import React, { useEffect, useState } from 'react';
import { AuthGet } from '../../common_var/httpService';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

function AuditLog() {
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDate, setCurrentDate] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(5)

  const getCurrentDate = () => {
    let date = new Date();
    date = date.toISOString();
    date = date.split('T')[0]
    setCurrentDate(date);
}

  useEffect(() => {
    getPracticeList();
    getCurrentDate()
  }, []);

  

  const getPracticeList = async () => {
  const access_id= sessionStorage.getItem("UserId");
  await AuthGet(`trace-actions/${access_id}`, 'admin').then((res) => {
  const enrichedLoanData = res?.data.map((item, index) => ({
    ...item,
    serialNumber: index + 1,
  }));
      
  setLoanData(enrichedLoanData);
  setPage(Math.ceil(enrichedLoanData.length / 10));
  setCurrentPage(1)})
    
  };

  const columns = [
    {
      dataField: 'serialNumber', 
      text: 'S.NO',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        
        return rowIndex + 1 + (currentPage - 1) * itemsPerPage;
      },
      headerStyle: {
        width: '50px',
      },
      style: {
        overflow: 'hidden',
        textAlign: 'center'
      },
    },
    {
      dataField: 'access_id',
      text: 'Access id',
      sort: true,
      headerStyle: {
        width: '150px',
      },
      style: {
        overflow: 'hidden',
      },
    },
    {
      dataField: 'ip',
      text: 'IP',
      sort: true,
      headerStyle: {
        width: '150px',
      },
      style: {
        overflow: 'hidden',
      },
    },
    {
      dataField: 'user_id',
      text: 'User id',
      sort: true,
      headerStyle: {
        width: '150px',
      },
      style: {
        overflow: 'hidden',
      },
    },
    {
      dataField: 'message',
      text: 'Message',
      sort: true,
      headerStyle: {
        width: '150px',
      },
      style: {
        overflow: 'hidden',
      },
    },
    {
      dataField: 'createdat',
      text: 'Created At',
      sort: true,
      headerStyle: {
        width: '150px',
      },
      style: {
        overflow: 'hidden',
      },
    },
    
  ];

  const defaultSorted = [
    // {
    //   dataField: 'id',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'loan_id',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'module',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'user',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'createdat',
    //   order: 'desc'
    // }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: itemsPerPage,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      setitemsPerPage(page)
    },
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <div>
      <div className="breadcrumbsection">
        <div>
          <div className="titleText "> Trace Actions</div>
        </div>
      </div>

      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={loanData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default AuditLog;
