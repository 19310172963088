import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initialState, reducer } from "./Context/Reducer";
import { StateProvider } from "./Context/StateProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";

let Exclude=({url})=>{
  let excludeUrl=[{url:'socket/getNotification'},{url:'socket/saveNotification'}
  ]
  let checkurl=[]
  for (let i = 0; i < excludeUrl.length; i++) {
  if( url.includes(excludeUrl[i].url)){
      checkurl.push(url)
  }}
  if(checkurl?.length!=0){
      return true
  }else{
      return false
  }
  
}
Axios.interceptors.request.use(
  function (config) {
    // document.body.classList.add(" ");
    var div1 = document.createElement("div");
    div1.setAttribute("class", "loderBlock");
    div1.setAttribute("id", "healthLoader");
    var div2 = document.createElement("div");
    // div2.setAttribute("class", "pulse");
    div1.appendChild(div2);
    if(!Exclude(config)){
      document.body.appendChild(div1);
    }
    return config;
  },
  function (error) {
    // document.body.classList.remove(" ");
    const element = document.getElementById("healthLoader");
    element?.remove();
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    // spinning hide
    const element = document.getElementById("healthLoader");
    element?.remove();
    // document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    // document.body.classList.remove("loading-indicator");
    const element = document.getElementById("healthLoader");
    element?.remove();

    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
