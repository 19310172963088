import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import BankIcon from "./../../assets/images/bank_icon.svg";
import CardIcon from "./../../assets/images/card_icon.svg";
import toast from "react-hot-toast";
import Toast from "../Toaster/Toast";
import Modal from "react-bootstrap/Modal";
import CustomModal from "../Modal/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { accountNumber, name, routingNumber } from "../Regex/Regex";
// import { io } from "socket.io-client";
// import Socket from "../Socket/Socket";

const CusModal = ({ btname, Paramsdata, fun, data, uploadData, stage, providerBank }) => {
  const [show, setShow] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const queryParams = useLocation();
  const pathname = queryParams.pathname;
  const [{ socket }] = useStateValue()
  var user_id = sessionStorage.getItem("UserId");

  let loan_id = sessionStorage.getItem("LoanId");
  const [addbank, setAddbank] = useState({
    bankName: "",
    holderName: "",
    accountNumber: "",
    routingNumber: "",
    agree: false,
    confirm: false,
    user_id: user_id,
  });
  const [addcard, setAddcard] = useState({
    billingAddress: "",
    fullName: "",
    cardNumber: "",
    csc: "",
    expires: "",
    agree: false,
    confirm: false,
    user_id: user_id,
  });

  const [filteredDocs, setFilteredDocs] = useState([]);
  console.log("DOCS", filteredDocs);

  const [errorval, setErrorVal] = useState(false);
  const [errorval1, setErrorVal1] = useState(false);
  const [errorval2, setErrorVal2] = useState(false);
  const [errorval3, setErrorVal3] = useState(false);
  const [errorval4, setErrorVal4] = useState(false);

  const [passerr, setPasserr] = useState(false);
  const [passerr1, setPasserr1] = useState(false);
  const [passerr2, setPasserr2] = useState(false);

  const handleClose = () => {
    setShow(false);
    addBank.resetForm();
  };
  const handleShow = () => setShow(true);
  // const [socket, setSocket] = useState(null);
  const [bankid, setBankid] = useState("");
  const [cardid, setCardid] = useState("");

  const inputCard = useRef();
  const inputExp = useRef();
  const inputCsc = useRef();
  const inputAcct = useRef();

  const inputBill = useRef();

  const addNewBank = async (e) => {
    e.preventDefault();
    console.log("mweeeeeeee", addbank);
    let data = {
      bankName: addbank.bankName,
      holderName: addbank.holderName,
      accountNumber: Number(addbank.accountNumber),
      routingNumber: Number(addbank.routingNumber),
      agree: addbank.agree,
      confirm: addbank.confirm,
      user_id: addbank.user_id,
    };
    await AuthPost(Paramsdata.url, data, Paramsdata.key)
      .then((res) => {
        console.log("add--->", res);
        if (res.data != undefined) {
          if (res.data.statusCode >= 400 || res.data.statusCode <= 410) {
            toast.error(res.data.message[0]);
          }
        }
        if (res.statusCode === 200) {
          toast.success("Bank Add successfully");

          fun();
          handleClose();
        }
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  const addBank = useFormik({
    initialValues: {
      bankName: "",
      holderName: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "",
      agree: false,
      confirm: false,
      user_id: user_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      bankName: Yup.string().required("Name of the Bank is required"),
      holderName: Yup.string().required(
        "Name of the Account Holder is required"
      ),
      accountNumber: Yup.string()
        .required("Bank Account Number is required")
        .matches(/^[0-9]{16}$/g, "Bank Account Number must be 16"),
      routingNumber: Yup.string()
        .required("Bank Routing Number is required")
        .matches(/^[0-9]{9}$/g, "Bank Rounting Number must be 9"),
      accountType: Yup.string()
        .required("Bank account type is required"),
      agree: Yup.boolean().oneOf([true], "Please Accept Terms and Condition"),
      confirm: Yup.boolean().oneOf([true], "Please Confirm The Details"),
    }),
    onSubmit: async (values) => {
      // debugger
      console.log("BANK", values);
      data = { ...values, loan_id: loan_id };
      await AuthPost(Paramsdata.url, data, Paramsdata.key)
        .then((res) => {
          console.log("add--->", res);
          if (res.data != undefined) {
            if (res.data.statusCode >= 400 || res.data.statusCode <= 410) {
              toast.error(res.data.message[0]);
              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res.data.message[0],
              //   toastType: "error",
              // });
            }
          }
          if (res.statusCode === 200) {
            // console.log("socket..modal", socket)
            // let userName = sessionStorage.getItem('UD_firstName')
            // userName = userName.charAt(0).toUpperCase() + userName.slice(1) + ' ' + sessionStorage.getItem('UD_lastName')
            // socket?.emit("sendNotification", {
            //   receiver_user_id: "30f2bc39-f5a0-4325-b4d2-ab7caea438c9",
            //   notification: { id: '30f2bc39-f5a0-4325-b4d2-ab7caea438c9', 'message': `${userName} added a new bank` },

            // });

            toast.success("Bank Added Successfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Bank Add successfully",
            //   toastType: "success",
            // });
            fun();
            handleClose();
          }
        })
        .catch((err) => {
          console.log("err--->", err);
        });
    },
  });

  const addNewCard = async (e) => {
    e.preventDefault();
    console.log("addNewCard---->", addcard);
    let data = {
      billingAddress: addcard.billingAddress,
      fullName: addcard.fullName,
      cardNumber: Number(addcard.cardNumber),
      csc: Number(addcard.csc),
      expires: addcard.expires,
      agree: addcard.agree,
      confirm: addcard.confirm,
      user_id: user_id,
    };
    await AuthPost(Paramsdata.url, data, Paramsdata.key)
      .then((res) => {
        console.log("add bank", res);
        if (res.data != undefined) {
          if (res.data.statusCode >= 400 || res.data.statusCode <= 410) {
            toast.error(res.data.message[0]);
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: res.data.message[0],
            //   toastType: "error",
            // });
          }
        }
        if (res.statusCode === 200) {
          toast.success("Card Add successfully");
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: "Card Add successfully",
          //   toastType: "success",
          // });
          fun();
          handleClose();
          addBank.resetForm();
        }
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  const changeBankAcct = async (e) => {
    e.preventDefault();
    console.log("Paramsdata.url->>>>>", Paramsdata.url);
    if (Paramsdata.url === `payment-method/cardchoose/${user_id}`) {
      let data = {
        card_id: cardid,
      };
      console.log(data);
      await AuthPut(Paramsdata.url, data, Paramsdata.key)
        .then((res) => {
          console.log("add bank", res);
          if (res.statusCode === 200) {
            toast.success("Bank changed successfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Bank changed successfully",
            //   toastType: "success",
            // });
            fun();
            handleClose();
          }
        })
        .catch((err) => {
          console.log("err--->", err);
        });
    }
    if (Paramsdata.url === `payment-method/bankchoose/${user_id}`) {
      let data = {
        bank_id: bankid,
      };
      console.log(data);
      await AuthPut(Paramsdata.url, data, Paramsdata.key)
        .then((res) => {
          console.log("add bank", res);
          if (res.statusCode === 200) {
            toast.success("Card Changed successfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Card Changed successfully",
            //   toastType: "success",
            // });
            fun();
            handleClose();
          }
        })
        .catch((err) => {
          console.log("err--->", err);
        });
    }
  };

  const handleDocs = (e) => {
    console.log("VALUE", e.target.files);
    let receivedFiles = e.target.files;
    const allowedExtensions = [".pdf", ".jpg", ".jpeg", ".png"];
    let allowedFiles = [];
    for (let i = 0; i < receivedFiles.length; i++) {
      console.log("Received", receivedFiles[i]?.name);
      if (
        allowedExtensions.some((key) => receivedFiles[i]?.name.endsWith(key))
      ) {
        allowedFiles.push(receivedFiles[i]);
      }
    }
    console.log("FINALCHECK", allowedFiles);
    setFilteredDocs(allowedFiles);
  };

  const handleDelete = (value) => {
    console.log("VALUE", filteredDocs, value, 1);
    const filtered = filteredDocs.filter((e) => {
      return e.name != value;
    });
    setFilteredDocs(filtered);
    // filteredDocs.slice(value,1) --> Didn't Work
  };

  const uploadDocument = async () => {
    let typeCheck = true;
    for (let i = 0; i < filteredDocs.length; i++) {
      if (!filteredDocs[i].hasOwnProperty("docType")) {
        toast.error(`Select Document Type Of ${filteredDocs[i].name}`);
        // dispatch({
        //   type: "TOAST",
        //   toastMessage: `Select Document Type Of ${filteredDocs[i].name}`,
        //   toastType: "error",
        // });
        typeCheck = false;
      }
    }
    if (typeCheck) {
      var formData = [];
      formData = new FormData();

      formData.append("loan_id", uploadData.id);
      formData.append("stage", stage);
      formData.append("headdocumentType", data.name);
      formData.append(
        "documentTypes",
        filteredDocs.map((e) => e.docType)
      );
      for (let i = 0; i < filteredDocs.length; i++) {
        formData.append("files", filteredDocs[i]);
      }
      console.log("formData", formData);
      await AuthFile(`${Paramsdata.url}`, formData, Paramsdata.key)
        .then((res) => {
          console.log("DOCUPLOAD", res);
          if (res.statusCode == 200) {
            setFilteredDocs([]);
            handleClose();
            toast.success("Document Uploaded Sucessfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Document Uploaded Sucessfully",
            //   toastType: "success",
            // });
          }
          if (res.data != undefined) {
            if (res.data.statusCode >= 400 && res.data.statusCode <= 410) {
              toast.error(res.data.message);
              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res.data.message,
              //   toastType: "error",
              // });
            }
          }
          console.log("res---->", res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let getdata = async () => {
    await AuthGet("payment/all/" + loan_id, "borrower")
      .then((res) => {
        // console.log(res);
        if (res["statusCode"] == 200) {
          //console.log(res["data"])

          setuserdata(res["data"]);
        } else {
          alert(res["message"]);
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };
  console.log("->", userdata);

  useEffect(() => {
    console.log("data---->", data);
    if (!providerBank) {
      getdata();
    }
    if (data && data.length > 1 && pathname === "/borrower/add-payment") {
      for (var i = 0; i < data.length; i++) {
        if (data[i].active_flag == "Y") {
          console.log(data[i].id);
          if (data[i].cardNumber != undefined) {
            setCardid(data[i].id);
          }
          if (data[i].accountNumber != undefined) {
            setBankid(data[i].id);
          }
        }
      }
    }
  }, [data]);

  console.log("dnvsdvidsivbi", addBank.values);

  return (
    <>
      <Toast />

      {pathname === "/borrower/add-payment" && btname === "Add bank" ? (
        <>
          <div className="displayFlex spaceBetween">
            <div>
              <h3 className="heading18 fontWeight500">
                {/* <img src={BankIcon} className="mr_8" /> */}
                Bank Account
              </h3>
            </div>

            <div className="displayFlex">
           {userdata.next_schedule?.length>0?<CustomModal
                btnName={"Make Payment"}
                portal={"borrower"}
                dynamicClass={"addbutton"}
                userdata={userdata.paymentScheduleDetails}
                borrowerfun={getdata}
              />:''}{" "}
              &nbsp;
              <div className="addbutton" onClick={handleShow}>
                {btname} +
              </div>
            </div>
          </div>
        </>
      ) : pathname === "/borrower/add-payment" && btname === "change" ? (
        <>
          <div className="displayFlex spaceBetween">
            <div className="greenColor cursorpointer" onClick={handleShow}>
              <i className="fa fa-plus-circle mr_8" aria-hidden="true"></i>{" "}
              {btname}
            </div>
          </div>
        </>
      ) : pathname === "/borrower/add-payment" &&
        btname === "Add Debit Card" ? (
        <div className="displayFlex spaceBetween whiteFrameHeader">
          <h3 className="heading18 fontWeight600">
            {/* <img src={CardIcon} className="mr_8" /> */}
            Card
          </h3>
          <button className="addbutton" onClick={handleShow}>
            {btname === "Add Debit Card" && "Add Card"}
          </button>
        </div>
      ) : pathname === "/borrower/add-payment" && btname === "Add +" ? (
        <button className="addbutton" onClick={handleShow}>
          {btname}
        </button>
      ) : pathname === "/dealer/loanapplications" && btname === "Required" ? (
        <div className="displayFlex spaceBetween">
          <div className="greenColor cursorpointer" onClick={handleShow}>
            {btname}
          </div>
        </div>
      ) : (
        <button className="btn btn-primary" onClick={handleShow}>
          {btname}
        </button>
      )}

      {btname === "Add bank" || providerBank ? (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add new bank account</Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>
                <div>
                  <form
                    id="form"
                    // name="bankAddForm"
                    // onSubmit={addNewBank}
                    onSubmit={addBank.handleSubmit}
                  >
                    <div className="mb_24">
                      <label className="labelText">Name of the Bank</label>
                      <input
                        type="text"
                        placeholder="Enter Bank Name as per your account statement"
                        className="MainInput"
                        id="bankName"
                        name="bankName"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "bankName",
                            name(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.bankName}
                      />
                      {addBank.touched.bankName && addBank.errors.bankName ? (
                        <small className="requiredText">
                          {addBank.errors.bankName}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label className="labelText">
                        Name of the Account Holder
                      </label>
                      <input
                        type="text"
                        placeholder=" Enter Your Name as per your account statement"
                        className="MainInput"
                        id="holderName"
                        name="holderName"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "holderName",
                            name(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.holderName}
                      />
                      {addBank.touched.holderName &&
                        addBank.errors.holderName ? (
                        <small className="requiredText">
                          {addBank.errors.holderName}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label htmlFor="banktype">Bank Account type</label>
                      <select
                        placeholder="Account type"
                        className="adminInput form-application"
                        id="banktype"
                        name="accountType"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.role}
                      >
                        <option defaultValue value="">Select bank account</option>
                        <option value={'Savings'} >Savings</option>
                        <option value={'Checking'} >Checking</option>

                      </select>
                      {addBank.touched.accountType &&
                        addBank.errors.accountType ? (
                        <small className="requiredText">
                          {addBank.errors.accountType}
                        </small>
                      ) : null}

                    </div>


                    <div className="mb_24">
                      <label className="labelText">Bank Account Number</label>
                      <input
                        type="text"
                        placeholder="Enter Your Account Number as per your account statement"
                        className="MainInput"
                        id="accountNumber"
                        name="accountNumber"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "accountNumber",
                            accountNumber(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.accountNumber}
                      />
                      {addBank.touched.accountNumber &&
                        addBank.errors.accountNumber ? (
                        <small className="requiredText">
                          {addBank.errors.accountNumber}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label className="labelText">Bank Routing Number</label>
                      <input
                        type="text"
                        placeholder="Enter Your Bank Routing Number as per your account statement"
                        className="MainInput"
                        id="routingNumber"
                        name="routingNumber"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "routingNumber",
                            routingNumber(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.routingNumber}
                      />
                      {addBank.touched.routingNumber &&
                        addBank.errors.routingNumber ? (
                        <small className="requiredText">
                          {addBank.errors.routingNumber}
                        </small>
                      ) : null}
                    </div>

                    <div className="customCheckbox customCheckboxVerification mb_12">
                      <input
                        type="checkbox"
                        id="agreeterms"
                        name="agree"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.agree}
                      />
                      <label htmlFor="agreeterms">
                        I hereby agree to the Terms & Conditions.{" "}
                      </label>
                      {addBank.touched.agree && addBank.errors.agree ? (
                        <small className="requiredText">{addBank.errors.agree}</small>
                      ) : null}
                    </div>

                    <div className="customCheckbox customCheckboxVerification mb_24">
                      <input
                        id="confrmBank"
                        type="checkbox"
                        className="mr30px"
                        name="confirm"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.bankName}
                      />
                      <label htmlFor="confrmBank">
                        I hereby confirm that the Bank account details furnished
                        above are true to my knowledge.{" "}
                      </label>
                      {addBank.touched.confirm && addBank.errors.confirm ? (
                        <small className="requiredText">{addBank.errors.confirm}</small>
                      ) : null}
                    </div>
                    <div className="displayFlex justifyConentEnd">
                      <div className="mr_12">
                        <button
                          type="reset"
                          className="mainPrimary_btn redLustClr_hover"
                          onClick={() => {
                            handleClose();
                            addBank.resetForm();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="mainPrimary_btn redLust_clr"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </>
      ) : btname === "change" ? (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Choose active bank account</Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>
                <div>
                  {/* <h5 className="pb20">Choose your active bank Account </h5> */}
                  <form
                    id="cardChooseForm"
                    name="cardChooseForm"
                    onSubmit={changeBankAcct}
                  >
                    <div className="mb_24">
                      {data?.length == 1 &&
                        data[0].accountNumber != undefined ? (
                        <>
                          <h5>
                            You Currently have one bank linked, to make a switch
                            add more than one Account
                          </h5>
                          <div
                            key={data[0].id}
                            className="displayFlex AlignItemCenter spaceBetween pb20"
                          >
                            <div>
                              {data[0].fullName} (******
                              {data[0].accountNumber.substring(
                                data[0].accountNumber.length - 4
                              )}
                              )
                            </div>
                            <input
                              type="radio"
                              name="chooseCrd"
                              checked={data[0].id}
                              onChange={(e) => {
                                console.log("ee");
                                setBankid(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      ) : data?.length == 1 &&
                        data[0].cardNumber != undefined ? (
                        <>
                          <h5>
                            You Currently have one bank linked, to make a switch
                            add more than one Account
                          </h5>
                          <div
                            key={data[0].id}
                            className="displayFlex AlignItemCenter spaceBetween pb20"
                          >
                            <div>
                              {data[0].fullName} (******
                              {data[0].cardNumber.substring(
                                data[0].cardNumber.length - 4
                              )}
                              )
                            </div>
                            <input
                              type="radio"
                              name="chooseCrd"
                              checked={data[0].id}
                              onChange={(e) => {
                                console.log("ee");
                                setCardid(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      ) : data?.length > 1 ? (
                        data.map((e, i) => {
                          console.log(bankid);
                          if (e.accountNumber !== undefined) {
                            return (
                              <>
                                <div
                                  key={e.id}
                                  className="displayFlex AlignItemCenter spaceBetween pb20"
                                >
                                  <div>
                                    {e.bankName} (******
                                    {e.accountNumber.substring(
                                      e.accountNumber.length - 4
                                    )}
                                    )
                                  </div>

                                  {/* { ( 
                          e.active_flag == 'Y' ?( */}
                                  <input
                                    type="radio"
                                    name="chooseCrd"
                                    value={bankid}
                                    onChange={() => {
                                      console.log("e.id--->", e.id);
                                      setBankid(e.id);
                                    }}
                                  />

                                  {/* ):(
                           <input type="radio" name="chooseCrd" checked={checkid}  value={checkid} onChange={()=>{
                              setCheckid(e.id)
                             }}  />
                         ))} */}
                                </div>
                              </>
                            );
                          }

                          if (e.cardNumber !== undefined) {
                            return (
                              <div
                                key={e.id}
                                className="displayFlex AlignItemCenter spaceBetween pb20"
                              >
                                <div>
                                  {e.bankName} (******
                                  {e.cardNumber.substring(
                                    e.cardNumber.length - 4
                                  )}
                                  )
                                </div>
                                {/* { ( 
                    e.active_flag == 'Y' ?( */}
                                <input
                                  type="radio"
                                  name="chooseCrd"
                                  value={cardid}
                                  onChange={() => {
                                    setCardid(e.id);
                                  }}
                                />

                                {/* ):(
                      <input type="radio" name="chooseCrd" checked={checkid} value={cardid} onChange={()=>{
                        setCardid(e.id)
                       }}  />
                    ))}
                  */}
                              </div>
                            );
                          }
                        })
                      ) : data == undefined ? (
                        <>
                          <h5>No banks available to make a switch</h5>
                        </>
                      ) : (
                        <>
                          <h5>No banks available to make a switch</h5>
                        </>
                      )}
                    </div>
                    {data?.length > 1 && (
                      <>
                        <div className="alignItemscenter displayFlex justifyConentEnd">
                          <div className="mr_12">
                            <button
                              type="button"
                              className="mainPrimary_btn redLustClr_hover"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="mainPrimary_btn redLust_clr"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </>
      ) : btname === "Add Debit Card" || btname === "Add +" ? (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add new Card </Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>
                <form
                  id="debitCardAddForm"
                  name="debitCardAddForm"
                  onSubmit={addNewCard}
                >
                  <div className="mb_24">
                    <label className="labelText">Full Name </label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Enter full name"
                      className="MainInput "
                      ref={inputAcct}
                      onChange={(e) => {
                        setAddcard((prev) => ({
                          ...prev,
                          [e.target.name]: name(e.target.value),
                        }));
                      }}
                      onBlur={() => {
                        let val = inputAcct.current.value.length;
                        if (val < 1) {
                          setErrorVal(true);
                        } else {
                          setErrorVal(false);
                        }
                      }}
                    />
                    {errorval === true && (
                      <>
                        <small className="requiredText">
                          {" "}
                          Name is required
                        </small>
                        <br />
                      </>
                    )}
                  </div>
                  <div className="mb_24">
                    <label className="labelText">Card Number</label>
                    <input
                      type="text"
                      name="cardNumber"
                      placeholder="Enter card Number"
                      className="MainInput "
                      ref={inputCard}
                      onChange={(e) => {
                        let val = inputCard.current.value.length;
                        const cardValue = inputCard.current.value
                          .replace(/\D/g, "")
                          .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
                        inputCard.current.value = !cardValue[2]
                          ? cardValue[1]
                          : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ""
                          }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
                        const numbers = inputCard.current.value.replace(
                          /(\D)/g,
                          ""
                        );

                        setAddcard((prev) => ({
                          ...prev,
                          [e.target.name]: numbers,
                        }));
                        if (val < 16) {
                          setPasserr1(true);
                        } else {
                          setPasserr1(false);
                        }
                      }}
                      onBlur={() => {
                        let val = inputCard.current.value.length;
                        if (val < 1) {
                          setErrorVal1(true);
                        } else {
                          setErrorVal1(false);
                        }
                      }}
                    />
                    {errorval1 === true && (
                      <>
                        <small className="requiredText">
                          Card Number is required
                        </small>
                        <br />
                      </>
                    )}
                    {passerr1 === true && (
                      <small className="requiredText">
                        Card number must be 16
                      </small>
                    )}
                  </div>
                  <div className="row mb_24">
                    <div className="col-md-6">
                      <label className="labelText">Expires </label>
                      <input
                        type="text"
                        name="expires"
                        placeholder="MM/YY"
                        mask="00/00"
                        className="MainInput "
                        ref={inputExp}
                        onChange={(e) => {
                          let val = inputExp.current.value.length;
                          const ExpValue = inputExp.current.value
                            .replace(/\D/g, "")
                            .match(/(\d{0,2})(\d{0,2})/);
                          inputExp.current.value = !ExpValue[2]
                            ? ExpValue[1]
                            : `${ExpValue[1]}/${ExpValue[2]}`;

                          setAddcard((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                          // if (val < 16) {
                          //   setPasserr2(true);
                          // }else{
                          //   setPasserr2(false);
                          // }
                        }}
                        onBlur={() => {
                          let val = inputExp.current.value.length;
                          if (val < 1) {
                            setErrorVal2(true);
                          } else {
                            setErrorVal2(false);
                          }
                        }}
                      />
                      {errorval2 === true && (
                        <>
                          <small className="requiredText">
                            {" "}
                            Card Expire Year is required
                          </small>
                          <br />
                        </>
                      )}
                      {/* {passerr2 === true && (
                      <small className="requiredText">
                      Card Number  must be  16
                      </small>
                      )} */}
                    </div>
                    <div className="col-md-6">
                      <label className="labelText">CSC</label>
                      <input
                        type="text"
                        name="csc"
                        mask="000"
                        placeholder="Enter 3 digits"
                        className="MainInput "
                        ref={inputCsc}
                        onChange={(e) => {
                          const val = inputCsc.current.value;
                          const CscValue = inputCsc.current.value
                            .replace(/\D/g, "")
                            .match(/(\d{0,3})/);
                          inputCsc.current.value = !CscValue[2]
                            ? CscValue[1]
                            : `${CscValue[1]}`;

                          setAddcard((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                          if (val < 3) {
                            setPasserr2(true);
                          } else {
                            setPasserr2(false);
                          }
                        }}
                        onBlur={() => {
                          let val = inputCsc.current.value.length;
                          if (val < 1) {
                            setErrorVal3(true);
                          } else {
                            setErrorVal3(false);
                          }
                        }}
                      />
                      {errorval3 === true && (
                        <>
                          <small className="requiredText">
                            {" "}
                            Card CSC Number is required
                          </small>
                          <br />
                        </>
                      )}
                      {passerr2 === true && (
                        <small className="requiredText">
                          Card CSC number must be 3
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="mb_24">
                    <label className="labelText">Billing address</label>
                    <input
                      type="text"
                      name="billingAddress"
                      placeholder="Enter billing address"
                      className="MainInput"
                      ref={inputBill}
                      onChange={(e) => {
                        setAddcard((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      onBlur={() => {
                        let val = inputBill.current.value.length;
                        if (val < 1) {
                          setErrorVal4(true);
                        } else {
                          setErrorVal4(false);
                        }
                      }}
                    />
                    {errorval4 === true && (
                      <>
                        <small className="requiredText">
                          {" "}
                          Billing Address is required
                        </small>
                        <br />
                      </>
                    )}
                  </div>
                  {/* <div className="col-12 mb-3 text-left"> */}
                  <div className="customCheckbox mb_12">
                    <input
                      type="checkbox"
                      name="agree"
                      id="agreeTerm"
                      onChange={(e) => {
                        setAddcard((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }));
                      }}
                    />
                    <label htmlFor="agreeTerm">
                      I agree to the Terms and Conditions
                    </label>
                  </div>

                  <div className="customCheckbox mb_24">
                    <input
                      type="checkbox"
                      id="confirmBank"
                      name="confirm"
                      onChange={(e) => {
                        setAddcard((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }));
                      }}
                    />
                    <label htmlFor="confirmBank">
                      I confirm the bank account details above
                    </label>
                  </div>
                  {/* </div> */}
                  <div className="displayFlex alignItemscenter justifyConentEnd">
                    <div className="mr_12">
                      <button
                        type="button"
                        className="mainPrimary_btn redLust_clr"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="mainPrimary_btn redLustClr_hover"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </Modal>
        </>
      ) : btname === "Required" ? (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{data.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {data.documenttype.join(", ")}
              {filteredDocs.map((e, i) => {
                return (
                  <div key={i}>
                    <div class="">
                      <h6>{e.name}</h6>
                      <select
                        class="form-control"
                        key={i}
                        onChange={(e) => {
                          filteredDocs[i].docType = e.target.value;
                        }}
                      >
                        <option value={""}>Select Document Type</option>
                        {filteredDocs &&
                          data.documenttype.map((m) => {
                            return <option value={m}> {m}</option>;
                          })}
                      </select>
                    </div>
                    <div
                      onClick={() => {
                        handleDelete(e.name);
                      }}
                    >
                      <img src="../../../assets/DeleteIcon.svg" />
                    </div>
                  </div>
                );
              })}
              <div class="item upload_box">
                <div class="">
                  <img class="mb_24" src="../../../assets/upload_icon.png" />
                  <input type="file" onChange={handleDocs} multiple />
                  <h4>FILE</h4>
                  <p>Drag and Drop or Click here to upload</p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button variant="secondary" onClick={uploadDocument}>
                Upload
              </button>
              <button variant="primary" onClick={handleClose}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <button variant="secondary" onClick={handleClose}>
              Close
            </button>
            <button variant="primary" onClick={handleClose}>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CusModal;
