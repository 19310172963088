import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Get } from "../../common_var/httpService";

const ViewMore = () => {
  const { id } = useParams();
  const [key, setKey] = useState(null);
  const URL = window.location.href;
  const splittedURL = URL?.split("//");

  const fetchKey = async () => {
    try {
      const resp = await Get(`loan/get-layoutkey/${id}`, "admin");
      if (resp.statusCode === 200) {
        setKey(resp?.data?.layout_key);
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  useEffect(() => {
    fetchKey();
  }, []);

  const isLocal = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  const url = isLocal
    ? `${process.env.REACT_APP_ONBOARDING_URL}/full-view/${id}`
    : `${splittedURL[0]}//app${key}-${splittedURL[1]?.split(".")[0]}.${
        process.env.REACT_APP_ONBOARDING_DOMAIN
      }/full-view/${id}`;

  return (
    <div className="iframe-container">
      <iframe
        src={url}
        width={"100%"}
        style={{ height: "1000px" }}
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default ViewMore;
