import React, { useEffect, useState } from "react";
import { AuthGet } from "../../common_var/httpService";
import InfoIcon from "../../assets/images/info_icon.svg";
import { useNavigate } from "react-router";
import {zerocheck}from "../../Components/Commonservice";
import { useStateValue } from "../../Context/StateProvider";
function FinancingDetails() {
  // let  monthDue=0;
  // let remainingBalance=0;
  // let nextDuedate=null;
  const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  const [initialvalues, setValues] = useState({
    monthDue: 0,
    remainingBalance: 0,
    nextDuedate: null,
  });

  const [loandata, setLoandata] = useState();
  const [{currency}, dispatch] = useStateValue();
  const [data, setData] = useState();
  const navigate = useNavigate()
  const get = async (loan_id) => {
    // await AuthGet("payment-details/getloandata/" + loan_id, "borrower")
    //   .then((res) => {
    //     //console.log(res);
    //     if (res["statusCode"] == 200) {
    //       setLoandata(res["data"][0]);
    //     } else {
    //       alert(res["message"]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error---->", error);
    //   });
  };
  const getPaymentDetails = async (loan_id) => {
    await AuthGet("payment/all/" + loan_id, "borrower")
      .then((res) => {
        // console.log(res);
        if (res["statusCode"] == 200) {
          //console.log(res["data"])
          setData(res["data"]);
          if (res.data?.next_schedule) {
            setValues({
              monthDue: res.data?.next_schedule[0].amount,
              remainingBalance: res.data?.next_schedule[0].unpaidprincipal,
              nextDuedate: res.data?.next_schedule[0].scheduledate,
            });
          }
        } else {
          alert(res["message"]);
        }
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };
  const getData = async () => {

    let userId = sessionStorage.getItem("UserId");
    let loan_id;
    await AuthGet(`users/dashboard/${userId}`, "borrower")
      .then((res) => {
        if (res["statusCode"] == 200) {

          loan_id = res.data["user_details"][0]?.loan_id
          if (loan_id) {
            get(loan_id);
            getPaymentDetails(loan_id);
            console.log('working')
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {

    getData()


  }, []);
  let commaForAmount=(number)=>{
    
    let commanum=number.toLocaleString('en-US', {
       minimumFractionDigits: 2,
       maximumFractionDigits: 2
 
   })
   
 
    return commanum
   }
  return (
    <>
      <div className="row mb_24">
        <div className="col-md-6">
          <div className="CardFrame">
            <div className="cardHeader">
              <div>
                <h5>Balance</h5>
              </div>
              <div>
                <button className="addbutton" onClick={() => { navigate('/borrower/add-payment') }}>Make Payment</button>
              </div>
            </div>
            <div className="cardbody">
            <h2>{currency}{commaForAmount(initialvalues?.remainingBalance)}</h2>
              <p>
              Minimum Amount <span>{currency}{commaForAmount(initialvalues?.monthDue)}</span> to be paid.
              </p>
            </div>

            <div className="cardFooter">
              <h5 className="displayFlex alignItemscenter">
                <img src={InfoIcon} />
                <span>Next payment Due </span>
              </h5>

              <div>
                <h4>{initialvalues?.nextDuedate}</h4>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-6">
          <div className="whiteFrame height100per">
            <div className="p16">
              <h3 className="heading18 fontWeight400 mb_12">
                Previous Payment History
              </h3>
              <div className="infotable_wrapper">
                <table>
                  <tbody>        
                  {data?.payment_details != null && data?.payment_details.length > 0 ? (
                    data?.payment_details.map((e, i) => {
                      return (
                        <tr key={i}>
                        
                          <th>
                            {e.paidat} th
                          </th>
                          
                          <td>${e.amount.toFixed(2)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      
                     
                      <td colSpan={2}>
                        <h5 className="nodataText">
                          No Previous Payment History Available
                        </h5>
                      </td>
                  
                     
                    </tr>
                   
                  )}
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {data?.user_details[0]?.autopayment == "Y" ? (
        <div className="col-md-6 infoBlock mb20px">
          <div className="info_icon"></div>
          <div>
            You have automatic payment set up and this payment will be
            automatically deducated from your bank account due to date.
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="whiteFrame mb_24">
        <div className="whiteFrameHeader">
          <h3 className="heading18 fontWeight400">
            Repayment Calendar
          </h3>
        </div>
        <div className="redlustTableWrapper">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Unpaid Principal Balance</th>
                <th>Principal</th>
                <th>Interest</th>
                <th>past due</th>
                <th>Amount</th>
                <th>Schedule Date</th>
              </tr>
            </thead>
            <tbody>
              {data && data?.paymentScheduleDetails?.map((d, i) => {
                return (
                  <tr key={d.id}>
                    <td>{i + 1}</td>
                    <td>{currency}{commaForAmount(d?.unpaidprincipal)}</td>
                    <td>{currency}{commaForAmount(d?.principal)}</td>
                    <td>{currency}{commaForAmount(d?.interest)}</td>
                    <td>{zerocheck(d?.pastDue,currency)}</td>
                    <td>{currency}{commaForAmount(d?.amount)}</td>
                    <td>{d?.scheduledate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="whiteFrame mb_24">
        <div className="whiteFrameHeader">
          <h3 className="heading18 fontWeight400">
            Transaction History
          </h3>
        </div>
        <div className="redlustTableWrapper" style={data?.transactionInformation.length!=0?{}:{height:'196px'}}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Amend Interest Balance</th>
                <th>Amend Principal Balance</th>
                <th>Direction</th>
                <th>Payment type</th>
                <th>Waive Interest</th>
                <th>Waive Principal</th>
                <th>Waive Past due</th>
                <th>Created at</th>
                
                {/* <th>Schedule Date</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.transactionInformation.length!=0?data?.transactionInformation?.map((d, i) => {
                let splitted=d?.amount.slice(0,2)
                let splitted2=d?.pastDue.slice(0,2)
                return (
                  <tr key={d?.id}>
                    <td>{i + 1}</td>
                    <td style={{whiteSpace:"nowrap"}}>{d?.id}</td>
                    <td>{currency}{splitted==".0"?"0"+d?.amount:d?.amount}</td>
                    <td>{currency}{d?.amendInterestBalance}</td>
                    <td>{currency}{d?.amendPrincipalBalance}</td>
                    <td>{d?.direction=='NoMethod'?"--":d?.direction}</td>
                    <td>{d?.typeOfPayment=='WaivePayment'?"Full Waive":d?.typeOfPayment=='PartialWaivePayment'?'Partial waive':d?.typeOfPayment=='ReFunded'?'Repaid':d?.typeOfPayment=='AmendPayment'?'Amend':d?.typeOfPayment}</td>
                    <td>{zerocheck(d?.waiveInterest , currency)}</td>
                    <td>{zerocheck(d?.waivePrincipal , currency)}</td>
                    <td>{currency}{d?.typeOfPayment.toLowerCase().includes('waive')?splitted2==".0"?"0":d?.pastDue:"0"}</td>
                    <td style={{whiteSpace:"nowrap"}}>{d?.createdat}</td>
                    {/* <td>{d.scheduledate}</td> */}
                  </tr>
                );
              })  : 
              <div style={{
                position: 'absolute',
                marginLeft: '270px',
                marginTop: '38px'}}>
                <center>No Transaction History Found</center>
                
             </div>}
            </tbody>
          </table>
        </div>
      </div>
      {/* <section className="pb_23 Border_borderRadius">
        <div className="portal_table table_responsive plr_24">
          <h4 className="SemiBold_14 mb_16">Financing</h4>
          <table>
            <tbody>
              <tr>
                <th>Financing reference</th>
                <td>{loandata?.loan_id}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>{loandata?.firstname}</td>
              </tr>
              <tr>
                <th>Amount financed</th>
                <td>{loandata?.monthlypayment}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{loandata?.status_flag}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="borderCenter" />
        <div className="portal_table table_responsive plr_24">
          <h4 className="SemiBold_14 mb_16">Financing details</h4>
          <table>
            <tbody>
              <tr>
                <th>Financing reference</th>
                <td>{loandata?.loan_id}</td>
              </tr>
              <tr>
                <th>Amount financed</th>
                <td>{loandata?.monthlypayment}</td>
              </tr>
              <tr>
                <th>APR</th>
                <td>{loandata?.apr}</td>
              </tr>
              <tr>
                <th>Financing term</th>
                <td>{loandata?.loanterm}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="borderCenter" />
        <div className="portal_table table_responsive plr_24">
          <h4 className="SemiBold_14 mb_16">Payment schedule</h4>
          <table>
            {data?.paymentScheduleDetails.map((d, i) => {
              return (
                <tbody key={d.id}>
                  <tr>
                    <th>
                      {new Date(d.scheduledate).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}
                    </th>
                    <td>{d.amount}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        <hr className="borderCenter" />
       
        <div className="content_box plr_30">
          <h4 className="SemiBold_14 mb_16">Payment Schedule Information</h4>
          <div className="Table_wrapped table_responsive">
            <table className="borderradius_unset">
              <thead>
                <tr>
                  <th style={{ width: "10px" }}>#</th>
                  <th>Unpaid Principal Balance</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Fees</th>
                  <th>Amount</th>
                  <th>Schedule Date</th>
                </tr>
              </thead>
              <tbody>
                {data?.paymentScheduleDetails?.map((d, i) => {
                  return (
                    <tr key={d.id}>
                      <td>{i + 1}</td>
                      <td>${d.unpaidprincipal}</td>
                      <td>${d.principal}</td>
                      <td>${d.interest}</td>
                      <td>${d.fees}</td>
                      <td>${d.amount}</td>
                      <td>{d.scheduledate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

       
      </section> */}
    </>
  );
}

export default FinancingDetails;
