import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const PlaidBankConnect = ({
  showBankDetails,
  bankAcc,
  handleBankData,
  borrowerbankAcc,
  setShowBankDetail,
  setShowUserDetail,
  showUserDetail,
  handleSendBankEmail,
  showBankDetail,
  handleUserInfo,
  activeBar,
  handleCurrentAcc,
  handleHistoricInfo,
  showCurrentAcc,
  showHistoricalAcc,
  currency,
  columnsCurrentChecking,
  defaultCurrentCheckingSorted,
  paginationCurrentChecking,
  borHistoryAcc,
  columnsHistoryChecking,
  defaultSortedHistoryChecking,
  paginationHistoryChecking,
  handleManualBankData,
  showManualAcc,
  userData,
  columnsManualBank,
  defaultManualBankSorted
}) => {
  const { SearchBar, ClearSearchButton } = Search;
  const [selectedBank, setSelectedBank] = useState(null);
  const [historyAcc, setHistoryAcc] = useState(null);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const handleSelectBank = (el) => {
    setSelectedBank(el);
    setShow((p) => !p);
  };

  const handleBankHistory = (el) => {
    setHistoryAcc(el);
    setShowHistory((p) => !p);
  };
  return (
    <>
      <div className="container-fluid mb20px frameshadow">
        <h5 className="frameshadow mb20px">Borrower Info</h5>
        {showBankDetails && (
          <div className="frameshadow-payment" style={{ cursor: "pointer" }}>
            <div>
              {Object.keys(bankAcc)?.length > 0 ? (
                bankAcc?.map((bank) => (
                  <div
                    onClick={() => {
                      handleBankData(
                        bank.user_id,
                        bank.bankholdername,
                        bank.bankholderemail,
                        bank.bankholderphone,
                        bank.bankholderaddress,
                        "B"
                      );
                    }}
                  >
                    Show Bank Details 1
                  </div>
                ))
              ) : borrowerbankAcc?.length > 0 ? (
                <div
                  onClick={() => {
                    setShowBankDetail(true);
                    setShowUserDetail({
                      Borrowerstatus: !showUserDetail.Borrowerstatus,
                    });
                  }}
                >
                  Show Bank Details
                </div>
              ) : (
                <button
                  onClick={() => handleSendBankEmail()}
                  type="button"
                  className="btn btn-primary"
                >
                  Request To Add Bank
                </button>
              )}
            </div>
          </div>
        )}
        {/* Three Heading div inside show bank detail ---- start */}
        <div style={{ marginBottom: "20px" }}>
          {showBankDetail && showUserDetail.Borrowerstatus ? (
            <div>
              <div className="frameshadow-payment-detail">
                <div style={{ display: "flex" }} className="mb20px">
                  <div
                    onClick={handleUserInfo}
                    className={
                      activeBar === "userInfo" ? "innerActive" : "innertab"
                    }
                  >
                    User Information
                  </div>
                  <div
                    onClick={() => handleCurrentAcc("B")}
                    className={
                      activeBar === "currentAcc" ? "innerActive" : "innertab"
                    }
                  >
                    Current Account Information
                  </div>
                  <div
                    onClick={handleHistoricInfo}
                    className={
                      activeBar === "historicInfo" ? "innerActive" : "innertab"
                    }
                  >
                    Historical Account Information
                  </div>
                </div>
                <div>
                  {/* user deatial --- start */}
                  {showUserDetail.Borrowerstatus &&
                  !showCurrentAcc &&
                  !showHistoricalAcc ? (
                    <div>
                      <table border={1} className="userinfotable">
                        <tbody>
                          <tr>
                            <td className="w30percent">Name</td>
                            <td>
                              {" "}
                              {showUserDetail?.name
                                ? showUserDetail?.name
                                : "NA"}{" "}
                            </td>
                          </tr>

                          <tr>
                            <td>Email</td>
                            <td>
                              {" "}
                              {showUserDetail?.email
                                ? showUserDetail?.email
                                : "NA"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>
                              {" "}
                              {showUserDetail.phone
                                ? showUserDetail.phone
                                : "NA"}
                            </td>
                          </tr>

                          <tr>
                            <td>Address</td>
                            <td>
                              {showUserDetail?.address
                                ? showUserDetail?.address
                                : "NA"}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div></div>
                    </div>
                  ) : // user detail --- end
                  // current acc pop up --- start
                  showUserDetail.Borrowerstatus &&
                    showCurrentAcc &&
                    !showHistoricalAcc ? (
                    <div>
                      {/* current acc bank 1 name */}
                      {borrowerbankAcc.map((el) => {
                        return (
                          <>
                            <div className="panel-heading mb20px">
                              <div onClick={() => handleSelectBank(el)}>
                                {el?.headername}
                              </div>
                              <div>
                                {/* to be shown*/}
                                {show && selectedBank === el && (
                                  <div>
                                    <div className="mt20px ">
                                      <table
                                        border={1}
                                        className="userinfotable mb20px"
                                      >
                                        <tbody>
                                          <tr>
                                            <td className="w30percent">
                                              Account Name
                                            </td>
                                            <td>
                                              {el?.name ? el?.name : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Type</td>
                                            <td>
                                              {el?.type ? el?.type : "NA"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td> Account Subtype</td>
                                            <td>
                                              {el?.subtype ? el?.subtype : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Number</td>
                                            <td>
                                              {el?.acno ? el?.acno : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Routing Number</td>
                                            <td>
                                              {el?.routing ? el?.routing : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              {" "}
                                              Account Wire Routing Number
                                            </td>
                                            <td>
                                              {el?.wire_routing
                                                ? el?.wire_routing
                                                : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Institution Type</td>
                                            <td>
                                              {el?.institution_id
                                                ? el?.institution_id
                                                : "NA"}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td> Account Current Balance</td>
                                            <td>
                                              {" "}
                                              {currency}{" "}
                                              {el?.current_bal
                                                ? el?.current_bal
                                                : "NA"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td> Account Available Balance</td>
                                            <td>
                                              {" "}
                                              {currency}{" "}
                                              {el?.available_bal
                                                ? el?.available_bal
                                                : "NA"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    <div>
                                      <ToolkitProvider
                                        bootstrap4
                                        keyField="id"
                                        data={el?.transactions}
                                        columns={columnsCurrentChecking}
                                        search
                                      >
                                        {(props) => (
                                          <div>
                                            <SearchBar {...props.searchProps} />
                                            <ClearSearchButton
                                              {...props.searchProps}
                                            />
                                            <hr />
                                            <BootstrapTable
                                              bootstrap4
                                              key="loan_ref"
                                              defaultSorted={
                                                defaultCurrentCheckingSorted
                                              }
                                              pagination={
                                                paginationCurrentChecking
                                              }
                                              {...props.baseProps}
                                            />
                                          </div>
                                        )}
                                      </ToolkitProvider>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : showUserDetail?.Borrowerstatus &&
                    !showCurrentAcc &&
                    showHistoricalAcc ? (
                    <div>
                      {Object.values(borHistoryAcc).map((el, i) => {
                        return (
                          <>
                            <div key={i} className="panel-heading mb20px">
                              <div>
                                <div onClick={() => handleBankHistory(el)}>
                                  Account Number -{" "}
                                  {Object.keys(borHistoryAcc)[i]}
                                </div>
                              </div>
                              {showHistory && historyAcc === el && (
                                <div className="mt20px">
                                  <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={Object.values(borHistoryAcc)[i]}
                                    columns={columnsHistoryChecking}
                                    search
                                  >
                                    {(props) => (
                                      <div>
                                        <SearchBar {...props.searchProps} />
                                        <ClearSearchButton
                                          {...props.searchProps}
                                        />
                                        <hr />
                                        <BootstrapTable
                                          bootstrap4
                                          key="loan_ref"
                                          defaultSorted={
                                            defaultSortedHistoryChecking
                                          }
                                          pagination={paginationHistoryChecking}
                                          {...props.baseProps}
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* Three Heading div inside show bank detail ---- end */}
        {/* Other bank name div --- start */}
        <div className="frameshadow-payment" style={{ cursor: "pointer" }}>
          <div>
            <div onClick={() => handleManualBankData()}>
              Other Bank Accounts
            </div>
          </div>
        </div>
        {/* Other bank table --- start */}
        <div>
          {showManualAcc ? (
            <div className="frameshadow-payment-detail">
              <div>
                <ToolkitProvider
                  bootstrap4
                  keyField="id"
                  data={userData?.manualBank}
                  columns={columnsManualBank}
                  search
                >
                  {(props) => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <ClearSearchButton {...props.searchProps} />
                      <hr />
                      <BootstrapTable
                        bootstrap4
                        key="id"
                        defaultSorted={defaultManualBankSorted}
                        // pagination={paginationCurrentChecking}
                        {...props.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Other bank table --- end */}
      </div>
      
    </>
  );
};

export default PlaidBankConnect;
