import React, { useEffect, useState } from 'react'
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
// import { useStateValue } from "../../Context/StateProvider";
import CusModal from '../../Components/modal copy/modal'
import CommonToggle from '../../Templates/toggle/toggle'
import InfoIcon from "../../assets/images/info_icon.svg";
import ActiveBankIcon from "../../assets/images/active_bank.svg";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
function ProviderAddPayment() {
  var user_id = sessionStorage.getItem('UserId')
  let addbank = "Add bank"
  let addbankurl = { url: `provider-management/addbank`, key: "admin" }
  let getbankaccounts={ url: `provider-management/provider-bank-list/${user_id}`, key: "admin" }
  let changeactive={url:`provider-management/setPrimary/${user_id}`,key:"admin"}
  const [bank, setBank] = useState([])



  //wrote the api now just have to excecute it 
  const showAccountNumber = (number) => {
    let result = '';
    for (let i = 0; i < number.length - 4; i++) {
      result = result + "X"
    }
    console.log(result + number.substring(number.length - 4))
    return result + number.substring(number.length - 4)

  }




  const getpayment = async () => {

    await AuthGet(getbankaccounts.url, "admin").then((res) => {
      console.log("get pay---->", res)
      if (res.data.statusCode == 403 || res.data.statusCode == 401) {
        toast.error(res.data.message);

      }
      // console.log("ijhj",res.data)
      let data = res.data
      if(data.length==1){
        if(data[0].active_flag=='N'){
          changeBankAcct(data[0].id)
        }
        }
        setBank(data)
        data.map((ele) => {
          ele.maskedAccountNumber = showAccountNumber(ele.accountnumber)
        });
      
    
      }).catch((err) => {
      console.log("get pay ------>", err)
    })

  }


  const changeBankAcct = async (e) => {
    // debugger
    let data = {
      bank_id: e,
    }
    console.log(data)
    await AuthPost(changeactive.url, data, changeactive.key).then((res) => {
      console.log("bank update", res)
      if (res.statusCode === 200) {
        toast.success(res.message[0]);
        getpayment()
      }
    }).catch((err) => {
      console.log("err--->", err)
    })
  }




  useEffect(() => {

     getpayment()

  }, [])









  return (
    <div className="col-md-12">

    <div className="whiteFrame p16 hidden-scroller mb20px">

  <div className="displayFlex alignItemscenter gap6 justifyConentEnd">
  <CusModal
        btname={addbank}
        Paramsdata={addbankurl}
        fun={getpayment}
        providerBank={true}
      />
  </div>

          <div className="infotable_wrapper">
              <table>
                <tbody>
                  {bank != null && bank.length > 0 ? (
                    bank.map((e, i) => {
                      return <tr key={i}>
                        <th className="fontWight500 washedBlack_textClr">
                          {e.maskedAccountNumber + "-" + e.bankname}
                        </th>
                        {e.active_flag == "N" ?
                          <td style={{ cursor: "pointer" }} onClick={() => changeBankAcct(e.id)}>Set as primary</td> :
                          <td className="">
                            <div className="displayFlex alignItemscenter gap6 justifyConentEnd">
                              <img src={ActiveBankIcon} />
                              <span className="spandexGreen_textClr">Active</span>
                            </div>
                          </td>
                        }
                      </tr>
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <h5 className="nodataText">
                          No Bank Account Added
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

      
      </div>



  
  )


}

export default ProviderAddPayment