import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider"
import toast from "react-hot-toast";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from "../../assets/images/brand_logo.svg";
import Brandlogo from "../../assets/images/brand_logo.svg";
import { io } from "socket.io-client";


const TwoFacAuth = ({ portal: key }) => {


  const formik = useFormik({
    initialValues: {
      newpw: '',
    }, enableReinitialize: true,
    validationSchema: Yup.object({
      newpw: Yup.string()
        .required('* is required')
    }),
    onSubmit: async (values) => {
      verifyOtp(values)

    }
  })




  const navigate = useNavigate();
  const [initialState, dispatch, authState, setAuthState] = useStateValue();
  const [otp, setOtp] = useState("")
  let title = "Health"
  let url = "users/authenticate-otp"
  let splittedURL = url.split("//");
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  if (key === "borrower") {
    url = "auth/confirm-OTP"
  }
  const data = { url: "users/signin", key };

  let id = sessionStorage.getItem('UserId')


  const verifyOtp = async (e) => {

    let data = {
      otp: Number(e.newpw),
      user_id: id
    }

    await Post(url, data, key)
      .then((res) => {
        console.log("res---->", res)

        console.log("res---->", data)
        if (res.statusCode === 200) {
          toast.success("Login Success")
          let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
            reconnection: false
          })
          dispatch({
            type: "SET_SOCKET",
            payload: socket,
          });
          socket?.emit("newUser", sessionStorage.getItem('UserId'));
          sessionStorage.setItem("login", true);
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: "Login Success",
          //   toastType: "success",
          // });
          // let ndata = {
          //   token: res.jwtAccessToken,
          //   user: data.user
          // }
          // setAuthState(ndata)
          if (key == 'admin') {
            sessionStorage.setItem("admin_token", res["jwtAccessToken"]);
            navigate("/admin/dashboard");
          }
          else if (key == 'borrower') {
            sessionStorage.setItem("borrower_token", res.jwtAccessToken)
            if (res.loanDetails[0]?.status_flag === "waiting") {
              sessionStorage.setItem(
                "borrower_token",
                res.jwtAccessToken
              );
              sessionStorage.setItem("userId", res["resuser"]["id"]);
              !isLocalhost
                ? window.open(
                  `${splittedURL[0]}//app${res.layout_key}-${splittedURL[1].split(".")[0]
                  }.${process.env.REACT_APP_ONBOARDING_DOMAIN
                  }/onboarding/${res.loanDetails[0].id}/${res.jwtAccessToken
                  }`,
                  "_self"
                )
                : window.open(
                  `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/${res.loanDetails[0].id}/${res.jwtAccessToken}`
                );
            } else if (res.loanDetails.length === 0) {
              sessionStorage.setItem("userId", res?.resuser?.id);
              sessionStorage.setItem(
                "borrower_token",
                res?.jwtAccessToken
              );
              navigate(`/selectprovider`);
            } else {
              sessionStorage.setItem(
                "borrower_token",
                res["jwtAccessToken"]
              );
              navigate("/borrower/payment-schedule");
            }
          }
        }
        if (res.statusCode == 101 || res.statusCode == 100) {
          toast.error(res.message[0])
          // dispatch({
          //   type: "TOAST",
          //   toastMessage: res.message[0],
          //   toastType: "error",
          // });
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  const gopage = (list, tabs) => {
    switch (list.name) {
      case "Dashboard":
        navigate("/admin/dashboard");
        break;
      case "Approved Application":
        navigate("/admin/approved");
        break;
      case "Pending Application":
        navigate("/admin/pendings");
        break;
      case "Incomplete Application":
        navigate("/admin/incomplete");
        break;
      case "Denied Application":
        navigate("/admin/dashboard");
        break;
      case "Funded Contracts":
        navigate("/admin/funded-contracts");
        break;
      case "Settings":
        gosetting(tabs[list.id]);
        break;
      case "Installer Management":
        navigate("/admin/installer");
        break;
      case "Users":
        navigate("/admin/users");
        break;
      case "Start Application":
        navigate("/admin/start-application");
        break;
      case "Funding Contracts":
        navigate("/admin/funding-contracts");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };

  const gosetting = (list) => {
    switch (list.name) {
      case "Audit Log":
        navigate("/admin/settings/auditlog");
        break;
      case "Questions":
        navigate("/admin/settings/questions");
        break;
      case "Admin Security":
        navigate("/admin/settings/admin-security");
        break;
      case "Roles":
        navigate("/admin/settings/roles");
        break;
      case "DecisionEngine":
        navigate("/admin/settings/decisionengine");
        break;
      default:
        sessionStorage.clear();
        break;
    }
  };


  return (
    <>
      <Toast />
      <div class="logincomponent">
        <div class="loginSection">
          <div class="login-form" style={{ textAlign: "left" }}>
            <div class="main-div">
              <div class="panel">
                <div className="brand_logo textAlignCenter">
                  {/* <div class="solarLogo"></div> */}
                  <img src={Brandlogo} alt='' style={{ marginBottom: "20px" }} />
                </div>

                <p>A OTP has been sent to your email</p>
                <p>Please enter the otp below to verify your login.If you cannot see the email from "{title}" in your inbox, make sure to check your spam folder.</p>
                <p>*OTP will expire in 5 minutes</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <label for="newpw"></label><br />
                <input type="number" name='newpw' id="newpw" class="form-control" placeholder="OTP"
                  value={formik.values.newpw}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.newpw && formik.errors.newpw ? (
                  <p className="error_text text-danger">{formik.errors.newpw}</p>
                ) : null}
                <a href="login">Back to login</a>
                <br />
                <button type="submit" class="greenbutton upperCase"
                  style={{ width: `100%` }} >Submit</button>

              </form>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}


export default TwoFacAuth