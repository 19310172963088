import React from "react";

const Button = ({ type, value, handleClick, disabled, fId }) => {
  return (
    <div>
      <button disabled={disabled}
        type={type}
        onClick={(e) => handleClick(e,fId)}
        className="btn_secondary bg_teal"
      >
        {value}
      </button>
    </div>
  );
};

export default Button;
