import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import brandlogo from "../../assets/images/brand_logo.svg";

const SelectLoanAmount = () => {
  const queryParams = useLocation()
  let providername = queryParams.pathname?.split("/")
  const [{ selectLoanPopup, currency }, dispatch] = useStateValue();
  console.log("CURRENCY",currency)
  console.log('selectLoanPopup::: ', selectLoanPopup);
  const [loanAmount, setLoanAmount] = useState(1000);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loanAmount >= 1000) {
      localStorage.setItem("loan_amount", loanAmount);
      dispatch({
        type: "SET_LOANAMOUNT_POPUP",
        payload: false
      })
      dispatch({
        type: 'SET_EMAILPHONE_POPUP',
        payload: true
      })

      if(providername[2]){
      navigate(`/create-account/${providername[2]}`)
      }else{
        navigate("/create-account");
      }
     
    } else {
      toast.error("Select loan amount alteast 1000");
    }
  };

  const handleClose = () => {
    dispatch({
      type: "SET_LOANAMOUNT_POPUP",
      payload: false
    })
  }
  return (

    <>
      <div className="fullScreen_bg">
        <Toast />
        <div className="topHeader">


          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" />
              </div>
            </div>
          </div>

          <div>



          </div>

        </div>

        <div className="whiteboxblock">



          <h2 className="textAlignCenter">Start Now</h2>
          <p className="textAlignCenter mb_24">
            We will offer ideal financing option that fits your needs{" "}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="howMuch_progress">
              <h3 className="textAlignCenter mb_24">
                Let us know your loan expectations
              </h3>
              <div className="loanAmountText mb_24">{currency}{Intl.NumberFormat('en-US').format(loanAmount)}</div>
              <div className="displayFlex mb_24 AlignItemCenter">
                <div className="pr20">{currency}1,000</div>
                <div className="slidecontainer">
                  <input
                    clasName="slider"
                    style={{ width: "100%" }}
                    type="range"
                    defaultValue={1000}
                    step={500}
                    min={1000}
                    max={20000}
                    onChange={(e) => setLoanAmount(+e.target.value)}
                  />
                </div>

                <div>{currency}20,000</div>
              </div>
            </div>
            <div className="mb24">
              <small>
                &nbsp;&nbsp;&nbsp;<span style={{ color: "red" }}>*</span> This
                amount is indicative. The final loan amount will vary based on
                your eligiblity and submitted documents{" "}
              </small>
            </div>
            <div className="textAlignCenter">
              <button type="submit" className=" btn_secondary displayunset bg_teal">
                Continue
              </button>
            </div>
          </form>


        </div>





      </div>
    </>


  );
};

export default SelectLoanAmount;
