import React, { useEffect, useState } from "react";
import breadcrumb_dash from "../../assets/new/breadcrumb_dash.svg";
import file_open_active from "../../assets/new/file_open_active.svg";
import search from "../../assets/new/search.svg";
import plus from "../../assets/new/plus.svg";
import { AuthGet, AuthPost } from "../../common_var/httpService";
// import { EmailRegex } from "../Regex/Regex";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import updateIcon from "../../assets/images/updateIcon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EmailRegex,
  handleLength,
  mobile,
  name,
  onlyAlphabet,
  onlyChar,
  url,
  zipcode,
} from "../../Components/Regex/Regex";
import toast from "react-hot-toast";
import {
  addressValidation,
  emailValidation,
  nameValidation,
} from "../CommonFunctions/Validations";
import 'react-phone-number-input/style.css';
import closeIcon from "../../assets/images/close-icon.svg"
import PhonesInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import { Roles } from "../../data_store";
import PhoneNumInput from "../../Components/PhoneNumInput/PhoneNumInput";

function PracticeAdmin() {
  const navigate = useNavigate();
  const [practiceName, setPracticeName] = useState("");
  const [loanData, setLoanData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [rolesList, setRolesList] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  let [editValue, setEditValue] = useState({});
  const [form, setForm] = useState({
    practiceName: "",
    email: "",
    role: "",
    firstName: "",
    lastName: "",
    locationName: "",
    phone: "",
    status: "",
    id: "",
  });
  const user = JSON.parse(sessionStorage.getItem('resuser'))

  // const handleShowEdit = (v) => {
  //   console.log("EDIT_DATA", v)
  //   setEditValue(v);
  //   setForm({
  //     practiceName: v.practicename,
  //     email: v.email,
  //     role: v.role,
  //     firstName: v.firstname,
  //     lastName: v.lastname,
  //     locationName: v.locationname,
  //     phone: v.mobile,
  //     status: v.status,
  //     id: v?.id ? v.id : ''
  //   });
  //   setShowEdit(true);
  //   return;
  // };

  useEffect(() => {
    getPracticeList();
    getPracticeName();
    getRoles()
  }, []);

  const getPracticeList = async () => {
    await AuthGet(`provider-management/fetch-admins`, "admin").then((res) => {
      // if (res?.statusCode === 200) {
        const enrichedLoanData = res?.data.map((item, index) => ({
          
          ...item,
          serialNumber: index + 1,
        }));
        setLoanData(enrichedLoanData);
        setPage(Math.ceil(enrichedLoanData.length / 10));
        setCurrentPage(1);
      // }
    });
  };

  const getPracticeName = async () => {
    await AuthGet(`provider-management`, "admin").then((res) => {
      if (res?.statusCode === 200) {
        setPracticeName(res?.allPractice);
      }
    });
  };

  const getRoles = async () => {
    await AuthGet(`roles/fetch-provider`, 'admin').then((res) => {
      if (res?.statusCode === 200) {
        const rolesList = res.data
          .filter((role) => role.id !== user.role)
          .map((role) => {
            role.displayName = Roles[role.name];
            return role;
          });
        setRolesList(rolesList);
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getPracticeId = (practicename) => {
    var rs = practiceName?.filter((x) => x.practicename === practicename);
    return rs[0].id;
  };

  // const handleSubmit = async (e, method) => {
  //   e.preventDefault();
  //   var rs = practiceName.filter(
  //     (x) => x.practicename === form?.practiceName
  //   );
  //   console.log("RESS_RESS", rs);

  //   let sendData = {
  //     practiceid: method === 'PUT' ? rs[0]?.id : form?.practiceName,
  //     email: form?.email,
  //     role: form?.role,
  //     firstname: form?.firstName,
  //     lastname: form?.lastName,
  //     location: form?.locationName,
  //     mobile: form?.phone,
  //     status: form?.status,
  //     id: form?.id ? form?.id : ''
  //   };

  //   console.log("FINAL_DATA", sendData)
  //   await AuthPost(`provider-management/create-provider-admin`, sendData, 'admin')
  //     .then((res) => {
  //       if (res?.statusCode === 200) {
  //         handleCloseEdit();
  //         handleClose();
  //         getPracticeList();
  //         getPracticeName();
  //         setForm({
  //           practiceName: '',
  //           email: '',
  //           role: '',
  //           firstName: '',
  //           lastName: '',
  //           locationName: '',
  //           phone: '',
  //           status: ''
  //         });
  //       }
  //     })
  // }

  const formValidation = Yup.object({
    // practiceName: Yup.string()
    //   .required('Provider is required'),
    practiceName: Yup.string().required("Provider is required"),
    role: Yup.string().required("Provider Role is required"),
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(EmailRegex, "Invalid email"),
    location: Yup.string().required("Location is required"),
    mobile: Yup.string()
      .required("Mobile Number is required")
      .test(
        "length",
        "Invalid Phone Number",
        (val) => isValidPhoneNumber(val === undefined ? "" : val)
      ),
    status: Yup.string().required("Status is required"),
    // role: Yup.string()
    //   .required('Role is required'),
  });

  const handleProvideData = async (values) => {
    values.practiceName =
      values.practiceName === undefined
        ? practiceName[0]?.id
        : values.practiceName;
    values.role = values.role === "" ? rolesList[0]?.displayName : values.role;

    let sendData = {
      practiceid: values.practiceName,
      email: (values.email)?.toLowerCase(),
      role: values.role,
      firstname: values.firstname,
      lastname: values.lastname,
      location: values.location,
      mobile: values.mobile,
      status: values.status,
      id: values.id ? values?.id : "",
      type: values.type
    };

    await AuthPost(
      `provider-management/create-provider-admin`,
      sendData,
      "admin"
    )
      .then((res) => {
        if (res?.statusCode === 200) {
          handleCloseEdit();
          handleClose();
          getPracticeList();
          getPracticeName();
          formikhandleProviderUser.resetForm();
          toast.success("Updated successfully");
        }else{
          toast.error(res["message"])
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const formikhandleProviderUser = useFormik({
    initialValues: {
      practiceName: practiceName[0]?.practicename,
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      location: "",
      role: "",
      status: "",
      id: "",
      type: "create"
    },

    validationSchema: formValidation,

    onSubmit: async (values) => {
      handleProvideData(values);
    },
  });
  const formikUpdateProviderUser = useFormik({
    initialValues: {
      practiceName: editValue?.practicename
        ? getPracticeId(editValue?.practicename)
        : "",
      firstname: editValue?.firstname,
      lastname: editValue?.lastname,
      email: (editValue?.email)?.toLowerCase(),
      mobile: editValue?.mobile,
      location: editValue?.locationname,
      role: editValue?.role,
      status: editValue?.status,
      id: editValue?.id,
      type: "update"
    },
    enableReinitialize: true,
    validationSchema: formValidation,

    onSubmit: async (values) => {
      handleProvideData(values);
    },
  });

  const columns = [
    {
      dataField: 'serialNumber',
      text: "S.NO",
      sort: true,
      // formatter: (e, column, columnIndex, row, rowIndex) => {
      //   let total = currentPage * 5;
      //   total = total - 5;
      //   let slNo = total + columnIndex + 1;
      //   return slNo;
      // },
      headerStyle: {
        width: "50px",
      },
      style: {
        overflow: "hidden",
        textAlign: 'center'
      },
    },
    {
      dataField: "firstname",
      text: "First Name",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "lastname",
      text: "Last Name",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "250px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "mobile",
      text: "Phone Number",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "practicename",
      text: "Provider",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "createdat",
      text: "Signed Up On",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "createdat",
      text: "Action",
      sort: true,
      formatter: () => {
        return (
          <button className="btn deleteIcon">
            <img src={updateIcon} />
          </button>
        );
      },
      events: {
        onClick: (e, columns, columnIndex, row, rowIndex) => {
          setEditValue(row);
          setShowEdit(true);
        },
      },
      headerStyle: {
        width: "100px",
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const defaultSorted = [
    // {
    //   dataField: 'firstname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'lastname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'email',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'phone',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'practicename',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'role',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'createdat',
    //   order: 'desc'
    // }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
    },
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <div>
      <div className="breadcrumbsection">
        <div>
          <div className="titleText "> Provider Users</div>
          {/* <span onClick={() => navigate("/admin/dashboard")} className='cursorpointer'> Home</span>  /   Practice Admin User */}
        </div>
        <button className="btn btn-primary " onClick={() => handleShow()}>
          Create Provider User
        </button>
      </div>

      <Modal show={show} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Create New Provider User</h4>
          </div>
          <img src={closeIcon} alt="close" onClick={handleClose}/>
        </Modal.Header>
        {/* <form> */}
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >
              <form
                onSubmit={formikhandleProviderUser.handleSubmit}
                className="formclass signupForm"
                style={{ width: "100%" }}
              >

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="FirstName">First Name</label>
                      <input
                        placeholder="First Name"
                        id="FirstName"
                        className="adminInput form-application"
                        name="firstname"
                        value={formikhandleProviderUser.values.firstname}
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "firstname",
                            nameValidation(e.target.value)
                          );
                        }}
                        onBlur={formikhandleProviderUser.handleBlur}
                      />
                      {formikhandleProviderUser.touched.firstname &&
                      formikhandleProviderUser.errors.firstname ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.firstname}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        placeholder="Last Name"
                        className="adminInput form-application"
                        name="lastname"
                        id="lastname"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.lastname}
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "lastname",
                            nameValidation(e.target.value)
                          );
                        }}
                        onBlur={formikhandleProviderUser.handleBlur}
                      />
                      {formikhandleProviderUser.touched.lastname &&
                      formikhandleProviderUser.errors.lastname ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.lastname}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="location">Location Name</label>
                      <input
                        placeholder="Location Name"
                        className="adminInput form-application"
                        name="location"
                        id="location"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.location}
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "location",
                            addressValidation(e.target.value)
                          );
                        }}
                        onBlur={formikhandleProviderUser.handleBlur}
                      />
                      {formikhandleProviderUser.touched.location &&
                      formikhandleProviderUser.errors.location ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.location}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="practiceName">Provider</label>
                      <select
                        className="adminInput form-application"
                        name="practiceName"
                        id="practiceName"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.practiceName}
                        onChange={formikhandleProviderUser.handleChange}
                        onBlur={formikhandleProviderUser.handleBlur}
                      >
                        <option value="">Provider</option>
                        {practiceName?.length > 0 &&
                          practiceName?.map((data) => {
                            const { id, practicename } = data;
                            return <option value={id}>{practicename}</option>;
                          })}
                      </select>
                      {formikhandleProviderUser.touched.practiceName &&
                      formikhandleProviderUser.errors.practiceName ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.practiceName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="role">Provider Role</label>
                      <select
                        className="adminInput form-application"
                        name="role"
                        id="role"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.role}
                        onChange={formikhandleProviderUser.handleChange}
                        onBlur={formikhandleProviderUser.handleBlur}
                      >
                        <option value="">Select Provider Role</option>
                        {rolesList?.map((data) => {
                          const { name, displayName } = data;
                          return <option value={name}>{displayName}</option>;
                        })}
                      </select>
                      {formikhandleProviderUser.touched.role &&
                      formikhandleProviderUser.errors.role ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.role}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="email">Email</label>
                      <input
                        placeholder="Email"
                        className="adminInput form-application"
                        name="email"
                        id="email"
                        value={formikhandleProviderUser.values.email}
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "email",
                            emailValidation(e.target.value)
                          );
                        }}
                        onBlur={formikhandleProviderUser.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikhandleProviderUser.touched.email &&
                      formikhandleProviderUser.errors.email ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.email}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="mobile">Phone Number</label>
                      <PhoneNumInput
                        className="mobileInput"
                        defaultCountry="US"
                        international
                        type="text"
                        formControlName="mobileNumber"
                        id="mobile"
                        // mask="000-00-0000"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "mobile",
                            e
                            );
                          }}
                          name="mobile"
                          onBlur={formikhandleProviderUser?.handleBlur}
                          value={formikhandleProviderUser?.values?.mobile}
                      />
                      
                      {formikhandleProviderUser.touched.mobile && formikhandleProviderUser?.errors?.mobile ? 
                        <p className="error_text text-danger">
                          {formikhandleProviderUser.errors.mobile}
                        </p>
                        : null
                      }

                      {/* <input
                        placeholder="Cell Phone"
                        id="mobile"
                        className="adminInput form-application"
                        mask="000-00-0000"
                        name="mobile"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.mobile}
                        onChange={(e) => {
                          formikhandleProviderUser.setFieldValue(
                            "mobile",
                            mobile(e.target.value)
                          );
                        }}
                        onBlur={formikhandleProviderUser.handleBlur}
                      /> */}

                      {/* {formikhandleProviderUser.touched.mobile &&
                      formikhandleProviderUser.errors.mobile ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.mobile}
                        </p>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="status">Status</label>
                      <select
                        className="adminInput form-application"
                        name="status"
                        id="status"
                        // onChange={handleInputChange}
                        value={formikhandleProviderUser.values.status}
                        onChange={formikhandleProviderUser.handleChange}
                        onBlur={formikhandleProviderUser.handleBlur}
                      >
                        <option value="" selected>
                          Status
                        </option>
                        <option value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                      {formikhandleProviderUser.touched.status &&
                      formikhandleProviderUser.errors.status ? (
                        <p className="error_text">
                          {formikhandleProviderUser.errors.status}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-primary" type="submit">
                      Add
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            </section>
          </Modal.Body>
        </div>
        {/* </form> */}
      </Modal>

      <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Update Provider Admin User</h4>
          </div>
        </Modal.Header>
        {/* <form> */}
        <div className="modelScroll">
          <Modal.Body>
            <section
              id="createApplicationFromBackend"
              className="start_application"
            >
              <form
                onSubmit={formikUpdateProviderUser.handleSubmit}
                className="formclass signupForm"
                style={{ width: "100%" }}
              >
                <div className="row">

                <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="FirstName">First Name</label>
                      <input
                        placeholder="First Name"
                        id="FirstName"
                        className="adminInput form-application"
                        name="firstname"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.firstname}
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "firstname",
                            nameValidation(e.target.value)                              
                          );
                        }}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      />
                      {formikUpdateProviderUser.touched.firstname &&
                      formikUpdateProviderUser.errors.firstname ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.firstname}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        placeholder="Last Name"
                        className="adminInput form-application"
                        name="lastname"
                        id="lastname"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.lastname}
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "lastname",
                            nameValidation(e.target.value)                              
                          );
                        }}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      />
                      {formikUpdateProviderUser.touched.lastname &&
                      formikUpdateProviderUser.errors.lastname ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.lastname}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="location">Location Name</label>
                      <input
                        placeholder="Location Name"
                        className="adminInput form-application"
                        name="location"
                        id="location"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.location}
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "location",
                            addressValidation(e.target.value)
                          );
                        }}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      />
                      {formikUpdateProviderUser.touched.location &&
                      formikUpdateProviderUser.errors.location ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.location}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="practiceName">Provider</label>
                      <select
                        className="adminInput form-application"
                        name="practiceName"
                        id="practiceName"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.practiceName}
                        onChange={formikUpdateProviderUser.handleChange}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      >
                        <option disabled="disabled" selected>
                          Provider
                        </option>
                        {practiceName?.length > 0 &&
                          practiceName?.map((data) => {
                            const { id, practicename } = data;
                            return <option value={id}>{practicename}</option>;
                          })}
                      </select>
                      {formikUpdateProviderUser.touched.practiceName &&
                      formikUpdateProviderUser.errors.practiceName ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.practiceName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="role">Provider Role</label>
                      <select
                        className="adminInput form-application"
                        name="role"
                        id="role"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.role}
                        onChange={formikUpdateProviderUser.handleChange}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      >
                        <option disabled="disabled" selected>
                          Select Provider Role
                        </option>
                        {rolesList?.map((data) => {
                          const { name, displayName } = data;
                          return <option value={name}>{displayName}</option>;
                        })}
                      </select>
                      {formikUpdateProviderUser.touched.role &&
                      formikUpdateProviderUser.errors.role ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.role}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="email">Email</label>
                      <input
                        placeholder="Email"
                        className="adminInput form-application"
                        name="email"
                        id="email"
                        value={formikUpdateProviderUser.values.email}
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "email",
                            emailValidation(e.target.value)
                          );
                        }}
                        onBlur={formikUpdateProviderUser.handleBlur}
                        // onChange={handleInputChange}
                      />
                      {formikUpdateProviderUser.touched.email &&
                      formikUpdateProviderUser.errors.email ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.email}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="mobile">Phone Number</label>
                      <PhoneNumInput
                        className="mobileInput"
                        defaultCountry="US"
                        international
                        type="text"
                        formControlName="mobileNumber"
                        id="mobile"
                        // mask="000-00-0000"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "mobile",
                            e
                            );
                          }}
                          name="mobile"
                          onBlur={formikUpdateProviderUser?.handleBlur}
                          value={formikUpdateProviderUser?.values?.mobile}
                      />
                       {formikUpdateProviderUser.touched.mobile && formikUpdateProviderUser?.errors?.mobile ? 
                        <p className="error_text text-danger">
                          {formikUpdateProviderUser.errors.mobile}
                        </p>
                        : null
                      }
                      {/* <input
                        placeholder="Cell Phone"
                        id="mobile"
                        className="adminInput form-application"
                        mask="000-00-0000"
                        name="mobile"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.mobile}
                        onChange={(e) => {
                          formikUpdateProviderUser.setFieldValue(
                            "mobile",
                            mobile(e.target.value)
                          );
                        }}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      /> */}
                      {/* {formikUpdateProviderUser.touched.mobile &&
                      formikUpdateProviderUser.errors.mobile ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.mobile}
                        </p>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="form-group col-md-4 col-xs-12 col-sm-4">
                    <div className="float-label">
                      <label htmlFor="status">Status</label>
                      <select
                        className="adminInput form-application"
                        name="status"
                        id="status"
                        // onChange={handleInputChange}
                        value={formikUpdateProviderUser.values.status}
                        onChange={formikUpdateProviderUser.handleChange}
                        onBlur={formikUpdateProviderUser.handleBlur}
                      >
                        <option value="">Status</option>
                        <option value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                      {formikUpdateProviderUser.touched.status &&
                      formikUpdateProviderUser.errors.status ? (
                        <p className="error_text">
                          {formikUpdateProviderUser.errors.status}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <Modal.Footer>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleCloseEdit}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-primary" type="submit">
                      Update
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            </section>
          </Modal.Body>
        </div>
      </Modal>

      <div className="frameshadow">
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={loanData}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key="loan_ref"
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default PracticeAdmin;
