import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import SetPassword from "../../Templates/SetPassword/SetPassword";
import Toast from "../../Components/Toaster/Toast";
import toast from "react-hot-toast";
import Landing from "../StartPage/Landing";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/images/brand_logo.svg";
import brandlogo from "../../assets/images/brand_logo.svg";

const SetBorrowerPassword = () => {
  const { token, id } = useParams();
  const [isToken, setIsToken] = useState(false);
  const [{ formData, errors, formSubmitted }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const queryParams = useLocation();
  const params = queryParams.pathname.split("/");
  let url = window.location.href;
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  let splittedURL = url.split("//");

  // console.log("Route:::", params);

  const checkToken = async () => {
    const bodyData = {
      token,
      id,
    };
    try {
      const resp = await Post("auth/verify-token", bodyData, "borrower");
      if (resp.statusCode === 200) {
        setIsToken(true);
        // toast.success(resp.message[0]);
        console.log(resp.message[0]);
      } else {
        toast.error("Invalid Token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("userId", id);
    checkToken();
  }, []);

  useEffect(() => {
    formSubmitted === false && dispatch({
      type: 'CLEAR_ERRORS'
    })
  },[formSubmitted])

  const handleSubmit = async (e) => {
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });
    e.preventDefault();
    console.log(token);
    if (isToken) {
      try {
        const bodyData = {
          password: formData.newpw,
          confirm_password: formData.confirmpw,
        };
        if (Object.keys(errors).length === 0) {
          const resp = await Post(
            `auth/create-password/${id}`,
            bodyData,
            "borrower"
          );
          if (resp.statusCode === 200) {
            toast.success(resp.message[0]);
            sessionStorage.setItem("borrower_token", resp?.data?.token);
            dispatch({
              type: "CLEAR_VALUES",
            });
            dispatch({
              type: "FORM_SUBMIT",
              payload: false,
            });
            const coBorrowertoken = resp?.data?.token;
            const CoBorrowerLoanId = resp?.data?.loan_id  === '' ?resp.data.loan_details[0]?.id : resp?.data?.loan_id
            const cosignorURL = !isLocalhost
              ? `${splittedURL[0]}//app${resp.data.key}-${
                  splittedURL[1].split(".")[0]
                }.${
                  process.env.REACT_APP_ONBOARDING_DOMAIN
                }/onboarding/${CoBorrowerLoanId}/${coBorrowertoken}`
              : `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/${CoBorrowerLoanId}/${coBorrowertoken}`;
            
              let route =
              params.length === 4
                ? "/selectprovider"
                : `/${params[1]}/selectprovider`;
            // console.log("params::: ", params);

            // params[1] === "cosignor"
            
            let check_id =resp.data.loan_details[0]?.practiceid
            console.log('check_id::: ', check_id);
            check_id != null
              ? window.open(cosignorURL, "_self")
              : navigate(route);
          } else if (resp.statusCode === 400) {
            toast.error(resp.message[0]);
            dispatch({
              type: 'CLEAR_ERRORS'
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="fullScreen_bg">
        <div className="topHeader">
          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" />
              </div>
            </div>
          </div>

          <div></div>
        </div>

        <div className="whiteboxblock">
          <h3 className=" mb_24  sectiontitle2  ">Password Setting</h3>

          <div>
            <SetPassword
              newPassword={"newpw"}
              confirmPassword={"confirmpw"}
              screenType={"setPassword"}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetBorrowerPassword;
