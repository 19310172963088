import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost } from "../common_var/httpService";
import Adminsidebar from "../Components/sidebar/adminsidebar";
import menuicon from "./../assets/images/menu-icon.svg";
// import footerlogo from "./../assets/images/whitelogo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Hidelogo from './../assets/images/hide_logo.svg'
import UserProfilelogo from "./../assets/images/userProfile.svg";
import NotificationIcon from "./../assets/images/notification_icon.svg";
import { useStateValue } from "../Context/StateProvider";
import SelectLoanAmount from "../Templates/SelectLoanAmount/SelectLoanAmount";
import StartApplication from "../Pages/StartApplication/StartApplication";
import Brandlogo from "../../src/assets/images/brand_logo.svg";
import markread from "../../src/assets/images/mark_Read.svg";
import threedot from "../../src/assets/images/three_dot.svg";
// import Brandlogo from "../../assets/images/brand_logo.svg";

const Admin = () => {
  const [{ selectLoanPopup, emailPhonePopup, socket }, dispatch] =
    useStateValue();
  const queryParams = useLocation();
  const navigate = useNavigate();
  const [subscriptionId, setSubscriptionId] = useState(null);
  const user_id = sessionStorage.getItem("UserId");
  const domain = window.location.href.split("/admin")[0];
  const [mobileToggle, setMobileToggle] = useState(false);
  const [webToggle, setWebToggle] = useState(false);
  const [selectid, setSelectid] = useState("");
  const [count, setCount] = useState(0);
  const [toggle, setToggle] = useState(false);
  const admin = useRef(JSON.parse(sessionStorage.getItem('resuser')));
  const handleToggleClick = () => {
    setMobileToggle((prev) => !prev);
  };

  const handleWebToggle = () => {
    setWebToggle((prev) => !prev);
  };

  let emitsocket = () => {
    socket?.emit("refreshEvent", {
      user_id: user_id,
      socket_id: socket.id,
    });
  };

  let pages = JSON.parse(sessionStorage.getItem("pages"));

  const checkRoles = (tabs) => {
    let IsRoleAvailable = pages.some((page) => page.name === tabs);
    return IsRoleAvailable;
  };

  const [notifications, setNotifications] = useState([]);
  const logout = async () => {
    navigate("/admin/login");
    sessionStorage.clear();
  };
  const globalConfig = () => {
    window.open(`${GlobalSettingsURL}`, "globalconfig");
  };

  const getSubscriptionId = async () => {
    let res = await AuthGet(`api/fetchsid/${user_id}`, "admin");
    setSubscriptionId(res.data[0].subscription_id);
  };

  const goToFormBuilder = () => {
    if(subscriptionId) {
      let FormBuilderURL = `${process.env.REACT_APP_FORMBUILDER_URL}/form-builder/${subscriptionId}`;
      window.open(`${FormBuilderURL}`, "formBuilder");
    } else {
      console.log("subscriptionId required");
    }
  };

  useEffect(() => {
    getSubscriptionId();
    getNotification();
  }, []);
  const handleButtonMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const displayNotification = (n) => {
    return (
      <div
        className={
          n.status == "Y"
            ? `notificationItem AlignItemCenter notificationItem_seen`
            : n.priority == "L"
            ? "notificationItem notificationItem_low AlignItemCenter "
            : n.priority == "H"
            ? "notificationItem AlignItemCenter  notificationItem_imp"
            : n.priority == "M"
            ? "notificationItem AlignItemCenter notificationItem_med"
            : ""
        }
      >
        <div className="notificationLeft">
          <div
            className={
              n.priority == "L"
                ? "legend_low"
                : n.priority == "M"
                ? "legend_medium"
                : n.priority == "H"
                ? "legend_important"
                : ""
            }
          ></div>
        </div>
        <div className="notification_content">
          <div className="displayFlex  AlignItemCenter ">
            <div
              onClick={() => {
                options(n, "markasread");
              }}
              className="displayFlex  AlignItemCenter min_width337px"
            >
              {" "}
              <h5>{n?.notification_type ? n?.notification_type : ""}</h5>
              <span className="notify_time">{n?.notification_time}</span>
            </div>
            <div
              onClick={() => {
                setSelectid(n.id);
              }}
              onBlur={() => {
                setSelectid("");
              }}
            >
              <button className="">
                <img style={{ margin: "10px" }} src={threedot} />
              </button>

              <div className="threedot_popup ">
                {selectid == n.id ? (
                  <>
                    <div
                      onClick={() => {
                        options(n, "clear");
                      }}
                      onMouseDown={handleButtonMouseDown}
                    >
                      clear
                    </div>

                    {n.status != "Y" ? (
                      <div
                        onClick={() => {
                          options(n, "markasread");
                        }}
                        onMouseDown={handleButtonMouseDown}
                      >
                        mark as read
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <p
            className="min_width337px"
            onClick={() => {
              options(n, "markasread");
            }}
          >
            {n.notification}
          </p>
        </div>
      </div>
    );
  };

  const getNotification = async () => {
    try {
      const resp = await AuthGet(`socket/getNotification/${user_id}`, "admin");
      if (resp.statuscode === 200) {
        setNotifications(resp.data);
        let unread = [];
        resp.data.filter((e) => {
          if (e.status == "N") {
            unread.push(e);
          }
        });
        setCount(unread);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let options = async (socket, type) => {
    setSelectid("");
    if (!socket) {
      return;
    }
    let { id, status } = socket;
    try {
      if (status == "Y" && type == "markasread") {
        return;
      }

      await AuthPost(
        `socket/notificationOption/${id}`,
        { type: type },
        "admin"
      ).then((res) => {
        if (res.statuscode == 200) {
          emitsocket();
          setSelectid("");
          setNotifications(res.data);
          let unread = [];
          res.data.filter((e) => {
            if (e.status == "N") {
              unread.push(e);
            }
          });
          setCount(unread);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket?.on("getNotification", (data) => {
      getNotification();
      // setNotifications((prev) => [...prev, data]);
    });
    socket?.on("refreshPage", () => {
      getNotification();
    });
  }, [socket]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [queryParams.pathname]);

  // let GlobalSettingsURL = process.env.REACT_APP_THEECODE_URL + `/control-panel/?username=${encodeURIComponent(process.env.REACT_APP_THEECODE_USERNAME)}&key=${encodeURIComponent(process.env.REACT_APP_THEECODE
  // )}&subscription_id=${encodeURIComponent(process.env.REACT_APP_SUBSCRIPTIONID)}`;

  let GlobalSettingsURL = `${process.env.REACT_APP_SASS_GLOBAL_URL}/control-panel/dashboard/${subscriptionId}/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imdva3Vsa2FuaXNoYmUrODE0QGdtYWlsLmNvbSIsInJvbGUiOiJ0ZW5hbnQiLCJpYXQiOjE2NzY5MTE5MTAsImV4cCI6MTY3NjkxNTUxMH0.pDldt3332gTmADMbh1GK7Xd8t6OTtHOdeZKopBksap4`;

  return (
    <div
      className={!webToggle ? "bluebackground" : "bluebackground hidesidebar"}
    >
      {selectLoanPopup && <SelectLoanAmount />}
      {emailPhonePopup && <StartApplication />}

      <div className="mobile_nav">
        <div className="mobile_header ">
          <div className="brand_logo">
            <img src={Brandlogo} className="whiteFilter"  alt="brandlogo"/>
          </div>
          <div>
            <button className="emptyButton" onClick={handleToggleClick}>
              <img src={menuicon} className="mobileMenuicon" alt="menuicon" />
            </button>
          </div>
        </div>
        <div className="header_show">
          <ul class="header_showlist">
            <li>
              <button
                className="MainButton aareRiverBrienz_Clr"
                target="_blank"
                onClick={globalConfig}
              >
                Global Config
              </button>
            </li>
            <li className="notificationDropdown ">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={NotificationIcon} />

                  <span className="count">{count ? count.length : 0}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="notification_modal">
                  <div
                    className="notify_modal"
                    onClick={() => {
                      setSelectid("");
                    }}
                  >
                    <div className="displayFlex alignItemscenter spaceBetween mb_12 notify_modalhead">
                      <div className="">
                        <h5 className="titleText  mb_4">
                          Notification{" "}
                          <span className="notify_count">
                            {count ? count.length : 0}
                          </span>
                        </h5>
                        <h6 className="fs12">
                          you have{" "}
                          <span className="notification_count">
                            {count.length}
                          </span>{" "}
                          unread messages
                        </h6>
                      </div>
                      <div className="displayFlex alignItemscenter">
                        <div>
                          {/* 
                        <span>
                          {" "}
                          Only show unread
                          <input
                            type="checkbox"
                            id="seen/unseentoggle"
                            onClick={(e) => {
                              setToggle(e.target.checked);
                            }}
                          />
                        </span> */}
                          Only show unread
                          <label className="switch">
                            <input
                              className="switch-input"
                              type="checkbox"
                              onClick={(e) => {
                                setToggle(e.target.checked);
                              }}
                            />
                            <span
                              className="switch-label"
                              // data-on="Enable"
                              // data-off="Disable"
                            >
                              {/* <img src={CloseOffIcon} /> */}
                            </span>
                            <span className="switch-handle">
                              {/* <img src={TickOnIcon} /> */}
                            </span>
                          </label>
                        </div>

                        <div
                          className="markread"
                          onClick={() => {
                            options(notifications[0], "markallasread");
                          }}
                        >
                          <img className="markreadicon" src={markread} />{" "}
                          <span className="markreadtext">Mark all as Read</span>
                        </div>
                      </div>
                    </div>

                    <div className="displayFlex notify_legend">
                      {" "}
                      <p className="displayFlex alignItemscenter ">
                        <div className="legend_important "></div> Important
                      </p>{" "}
                      <p className="displayFlex alignItemscenter">
                        <div className="legend_medium"></div> Medium
                      </p>{" "}
                      <p className="displayFlex alignItemscenter">
                        <div className="legend_low"></div> Low
                      </p>
                    </div>
                    {/* <div>
                      <button className="emptyButton">
                        <img src={MenuhorizontalIcon} />
                      </button>
                    </div> */}
                  </div>

                  {toggle
                    ? notifications
                        .filter((unseensnoti) => {
                          return unseensnoti.status == "N";
                        })
                        .map((unseen) => displayNotification(unseen))
                    : notifications.map((notification) =>
                        displayNotification(notification)
                      )}

                  {/* <div className="notificationItem">
                  <div className="notificationLeft">
                    <img src={UserProfilelogo} />
                  </div>
                  <div className="notification_content">
                    <h5>Application Triggered</h5>
                    <p>New Application has been triggered</p>
                  </div>
                </div> */}
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="userProfile">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="displayFlex alignItemscenter">
                    <div className="mr_12">
                      <img src={UserProfilelogo} />
                    </div>
                    <div>
                      <h5>Hello {admin.current['firstname'] ?? "Admin"}!</h5>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item onClick={() => setShow(true)}>Profile</Dropdown.Item> */}

                  <Dropdown.Item
                    onClick={() => {
                      navigate("/admin/admin-security");
                    }}
                  >
                    Security
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      sessionStorage.clear();
                      navigate("/admin/login");
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
      <div className="webview_nav">
        <div className="logoutblock">
          <div className="logosection">
          <div className="displayFlex">
              <div className="brand_logo ">
                <img src={Brandlogo} className="whiteFilter" />
              </div>
              <div className="hide_logo">
                {/* <img
                alt="hidelogo"
                  src={Hidelogo}
                  className="whiteFilter"
                  onClick={handleWebToggle}
                /> */}
              </div>
              <div className="pointer" 
               style={{height :"50px"}}
               >
                <img
                style={{marginRight : "29px", width:"24px", marginTop:"13px" }}
                     alt="hidelogo"
                  src={menuicon}
                  className="mobileMenuicon"
                  onClick={handleWebToggle}
                />
              </div>
            </div>
          </div>

          <ul class="user_admin">
            {checkRoles("global_config") && (
              <li>
                <button
                  className="MainButton aareRiverBrienz_Clr"
                  target="_blank"
                  onClick={globalConfig}
                >
                  Global Config
                </button>
              </li>
            )}
            <li className="main_modal">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={NotificationIcon} />

                  <span className="count">{count ? count.length : 0}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p0">
                  <div className="notify_modal">
                    <div className="displayFlex alignItemscenter spaceBetween mb_12 notify_modalhead">
                      <div className="">
                        <h5 className="titleText  mb_4">
                          Notification{" "}
                          <span className="notify_count">
                            {count ? count.length : 0}
                          </span>
                        </h5>
                        <h6 className="fs12">
                          you have{" "}
                          <span className="notification_count">
                            {count.length}
                          </span>{" "}
                          unread messages
                        </h6>
                      </div>
                      <div className="displayFlex alignItemscenter fs10">
                        {" "}
                        <div className="displayFlex">
                          <div>
                            {" "}
                            {/* <span>
                          {" "}
                          Only show unread
                          <input
                            type="checkbox"
                            id="seen/unseentoggle"
                            onClick={(e) => {
                              setToggle(e.target.checked);
                            }}
                          />
                        </span> */}
                            Only show unread
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                className="switch-input"
                                type="checkbox"
                                onClick={(e) => {
                                  setToggle(e.target.checked);
                                }}
                              />
                              <span
                                className="switch-label"
                                // data-on="Enable"
                                // data-off="Disable"
                              >
                                {/* <img src={CloseOffIcon} /> */}
                              </span>
                              <span className="switch-handle">
                                {/* <img src={TickOnIcon} /> */}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div
                          className="markread"
                          onClick={() => {
                            options(notifications[0], "markallasread");
                          }}
                        >
                          <img className="markreadicon" src={markread} />{" "}
                          <span className="markreadtext">Mark all as Read</span>
                        </div>
                      </div>
                    </div>
                    <div className="displayFlex notify_legend">
                      {" "}
                      <p className="displayFlex alignItemscenter ">
                        <div className="legend_important "></div> Important
                      </p>{" "}
                      <p className="displayFlex alignItemscenter">
                        <div className="legend_medium"></div> Medium
                      </p>{" "}
                      <p className="displayFlex alignItemscenter">
                        <div className="legend_low"></div> Low
                      </p>
                    </div>
                    {/* <div>
                      <button className="emptyButton">
                        <img src={MenuhorizontalIcon} />
                      </button>
                    </div> */}
                  </div>

                  {toggle
                    ? notifications
                        .filter((unseensnoti) => {
                          return unseensnoti.status == "N";
                        })
                        .map((unseen) => displayNotification(unseen))
                    : notifications.map((notification) =>
                        displayNotification(notification)
                      )}

                  {/* <div className="notificationItem">
                  <div className="notificationLeft">
                    <img src={UserProfilelogo} />
                  </div>
                  <div className="notification_content">
                    <h5>Application Triggered</h5>
                    <p>New Application has been triggered</p>
                  </div>
                </div> */}
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="userProfile">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="displayFlex alignItemscenter">
                    <div className="mr_12">
                      <img src={UserProfilelogo} />
                    </div>
                    <div>
                      <h5>Hello {admin.current['firstname'] ?? "Admin"}!</h5>
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item onClick={() => setShow(true)}>Profile</Dropdown.Item> */}

                  <Dropdown.Item
                    onClick={() => {
                      navigate("/admin/admin-security");
                    }}
                  >
                    Security
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      sessionStorage.clear();
                      socket?.emit("removeUser", {});
                      navigate("/admin/login");
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            {/* <li>
            <a
              class="logoutText"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={logout}
            >
              <img src={logouticon} className=" " /> */}
            {/* <i class="fa fa-fw fa-sign-out"></i> Logout */}

            {/* </a>
          </li> */}
          </ul>
        </div>
      </div>
      <div
        className={
          queryParams.pathname !== "/admin/login" && mobileToggle
            ? "page_wrapper show"
            : "page_wrapper"
        }
      >
        {queryParams.pathname !== "/admin/login" && (
          <div>
            <Adminsidebar goToFormBuilder={goToFormBuilder} />
          </div>
        )}
        <div
          className={
            queryParams.pathname !== "/admin/login" && "content_wrapper"
          }
        >
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Admin;
