import React, { useEffect, useState, useTransition } from "react";
import axios from "axios";
import ReactJson from "react-json-view";
import { useLocation } from "react-router-dom";
import { AuthGet } from "../../common_var/httpService";
import { Loader } from "../Components/Loader";
import cr_loader from "../../assets/cr_loader.gif";

const CreditReport = ({ userData, creditData }) => {
  //console.log('creditData::: ', creditData);
  // const [offers, setOffers] = useState([]);
  // const [rules, setrules] = useState([]);
  // const [creditScore, setCreditScore] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  //console.log('isLoading::: ', isLoading);

  const [creditScore, setCreditScore] = useState([]);
  const creditScoreMeta = creditData?.creditReport || [];
  const rules = creditData?.rules || [];

  const [isPending, startTransition] = useTransition();
  const queryParams = useLocation();
  
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  //console.log("creditScore::", creditScore);
  //console.log("rules::", rules);
  //console.log("offers", offers);

  useEffect(() => {
    startTransition(() => setCreditScore(creditData?.creditReport || []));
  }, []);

  // useEffect(() => {
  //   setOffers(creditData?.offers || []);
  //   setCreditScore(creditData?.creditReport || []);
  //   setrules(creditData?.rules || []);
  //   setIsLoading(false);
  // }, [creditData]);

  return (
    <div>
      {/* {isLoading && <Loader />} */}
      <div className="frameshadow mb_30">
        {creditScoreMeta?.length !== 0 ? (
          <table className="table">
            <thead>
              <tr style={{ fontSize: "16px", fontWeight: "600" }}>
                Credit Report Factors
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Credit Score</td>
                <td>{creditScoreMeta?.creditScore}</td>
              </tr>
              <tr>
                <td>Trades</td>
                <td>
                  {
                    creditScoreMeta?.screenTracking?.transUnion?.response?.product
                      ?.subject?.subjectRecord?.custom?.credit?.trade?.length
                  }
                </td>
              </tr>
              <tr>
                <td>Collections</td>
                <td>
                  {
                    creditScoreMeta?.screenTracking?.transUnion?.response?.product
                      ?.subject?.subjectRecord?.custom?.credit?.collection
                      ?.length
                  }
                </td>
              </tr>
              <tr>
                <td>Inquiries</td>
                <td>
                  {
                    creditScoreMeta?.screenTracking?.transUnion?.response?.product
                      ?.subject?.subjectRecord?.custom?.credit?.inquiry?.length
                  }
                </td>
              </tr>
              <tr>
                <td>No. of Credit Cards</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <tr>No Report Found</tr>
          </table>
        )}
      </div>

      <div className="frameshadow mb_30">
        <table className="table">
          <thead>
            <tr style={{ fontSize: "16px", fontWeight: "600" }}>
              Credit Rules
            </tr>
          </thead>
          {rules?.length > 0 ? (
            <tbody>
              {rules?.map((rule) => (
                <tr>
                  <td>{rule?.ruleShortDescrioption}</td>
                  <td>{rule?.ruleMessage.split(": ")[1]}</td>
                  <td
                    className={
                      rule?.ruleStatus === "PASS" ? "trueGreen" : "falseRed"
                    }
                  >
                    {rule?.ruleStatus === "PASS" ? "Pass" : "Fail"}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <table>
              <tr>No Rules Found</tr>
            </table>
          )}
        </table>
      </div>

      {/* <div className="frameshadow mb_30 ">
        <h6>
          <strong>Credit Check Raw Data</strong>
        </h6>
        {creditScore?.length !== 0 ? (
          <ReactJson src={creditScore} displayDataTypes={false} />
        ) : (
          <table>
            <tr>No Report Found</tr>
          </table>
        )}
      </div> */}

      <div className="frameshadow mb_30 ">
        {
          creditScoreMeta?.length === 0 ? 
            <table>
              <tr>No Report Found</tr>
            </table>
            :
            <div>
              <h6>
                <strong>Credit Check Raw Data</strong>
              </h6>
              {creditScore?.length !== 0 ? (
                <ReactJson src={creditScore} displayDataTypes={false} />
              ) : (
                <div className="cr_loader ">
                  <img src={cr_loader} alt="Loading..." />
                </div>
              )}
            </div>
        }
      </div>
    </div>
  );
};

export default CreditReport;
