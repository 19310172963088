import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { AuthGet } from "../../common_var/httpService";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CustomModal from '../../Components/Modal/Modal';
import {zerocheck}from "../../Components/Commonservice";
import { useStateValue } from '../../Context/StateProvider';
const PaymentSchedule = ({ userData, getUserDetails }) => {
    const [{currency}, dispatch] = useStateValue();
    const queryParams = useLocation()
    let params = queryParams.pathname.split('/');
    const loanId = params[params.length - 1];
    const loanStage = params[params.length - 2];
    // const [showPaymentSch, setShowPaymentSch] = useState(false);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [deferdata, setdeferdata] = useState([])
    useEffect(() => {
  
    }, [])
    useEffect(() => {
        // getlist();
        setPage(Math.ceil(userData?.paymentScheduleDetails?.length / 10));
        setCurrentPage(1);
    }, [queryParams]);

    // const getlist = async () => {
    //     console.log("params Id: ", loanId);
    //     console.log("stage: ", loanStage);

    //     await AuthGet(`loanstage/${loanId}/${loanStage}`, "admin")
    //         .then((res) => {
    //             if (res["statusCode"] == 200) {
    //                 console.log("Res Data: ", res.data);
    //                 setUserData(
    //                     {
    //                         from_details: res.data.from_details,
    //                         stage: res.data.stage[0].status_flag,
    //                         paymentScheduleDetails: res.data.paymentScheduleDetails,
    //                         transactionInformation: res.data.transactionInformation
    //                     }
    //                 );
    //                 setPage(Math.ceil(res.data.paymentScheduleDetails.length / 10));
    //                 setCurrentPage(1);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    // console.log("User data: ", userData);
 

    const columns = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "unpaidprincipal", text: 'Unpaid Principal Balance',
            formatter: (cell) => {
                return currency + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "principal", text: 'Principal',
            formatter: (cell) => {
                return currency + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "interest", text: 'Interest',
            formatter: (cell) => {
                return currency + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        // {
        //     dataField: "fees", text: 'Fees',
        //     formatter: (cell) => {
        //         if (cell === ".00") {
        //             return "$ 0.00";
        //         }
        //         return currency + cell;
        //     },
        //     style: {
        //         overflow: 'hidden'
        //     }
        // },
        {
          dataField:"pastDue",text:'Past due',
          
          formatter:(cell)=>{
          return zerocheck(cell,currency)
          }
        },
        {
            dataField: "amount", text: 'Amount',
            formatter: (cell) => {
                return currency + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "scheduledate", text: 'Schedule Date',
            formatter: (cell) => {
                return cell?.split("T")[0];
            },
            style: {
                overflow: 'hidden'
            }
        },
        // {
        //     dataField: "paidat", text: 'Paid Date'
        // }
    ];

    const pagination = paginationFactory({
        page: page,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
            setCurrentPage(page);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    const columnsTransaction = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {dataField:"id",text:"Transaction Id",
        formatter: (cell) => {
            return  cell;
        },
        style: {
            overflow: 'hidden'
        }
    
    },
        {
            dataField: "amount", text: 'Amount',
            formatter: (cell) => {
                if(cell){
                    let splitted=cell.slice(0,2)
                if(splitted=='.0'){
                return `${currency}0` + cell;
                }else{
                    return currency+ cell;
                }
               }},
            style: {
                overflow: 'hidden'
            }
        } ,

        {
            dataField: "amendInterestBalance", text: 'Amend Interest Balance ',
            formatter: (cell) => {
            return zerocheck(cell,currency)
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "amendPrincipalBalance", text: 'Amend Principal Balance',
            formatter: (cell) => {
                return zerocheck(cell,currency)
                },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "direction", text: 'Direction',
            formatter: (cell) => {
                if(cell=='NoMethod'){
                    return '- -'
                }else{
                return cell;
            }},
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "typeOfPayment", text: 'Payment Type',
            formatter: (cell) => {
                switch (cell) {
                    case 'WaivePayment':
                        return 'Full Waive';
                    case 'PartialWaivePayment':
                        return 'Partial Waive';
                    case 'ReFunded':
                        return 'Repaid';
                    case 'AmendPayment':
                        return 'Amend';
                    default :
                        return cell;
                }},
               
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "waiveInterest", text: 'waive Interest',
            formatter: (cell) => {
                return zerocheck(cell,currency)
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "waivePrincipal", text: 'Waive Principal',
            formatter: (cell) => {
             return zerocheck(cell,currency)
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "pastDue", text: 'Waive past due',
            formatter: (cell,row) => {
                
                let type=row.typeOfPayment.toLowerCase().includes('waive')
                
                
                if(type){
                    // console.warn('praveen',cell)
                    if(cell?.slice(0, 1)=="."){
                        return `${currency}0`+cell
                    }else{
                        return currency+cell
                    }
                    }
                else{
                    return `${currency}0.00`
                }
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "createdat", text: 'Created at',
            style: {
                overflow: 'hidden'
            }
        },
    ];

    const columnforTransaction = [
        {
            dataField: "", text: 'S.NO',
            formatter: (e, column, columnIndex, row, rowIndex) => {
                let total = currentPage * 5;
                total = total - 5;
                let slNo = total + columnIndex + 1;
                console.log("Total: ", total);
                return slNo;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "amount", text: 'Amount',
            formatter: (cell) => {
                return currency + cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "transactionType", text: 'Transaction Type',
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "paymentmode", text: 'Payment Mode',
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "transactionDate", text: 'Transaction Date',
            formatter: (cell) => {
                return cell;
            },
            style: {
                overflow: 'hidden'
            }
        },
        {
            dataField: "status", text: 'Status',
            style: {
                overflow: 'hidden'
            }
        }
    ];
    const getUserDetails1 = async () => {
        console.log("Loan details Loan Id: ", loanId);

        await AuthGet(`loanstage/defer-and-current/${loanId}/${loanStage}`, "admin")
            .then((res) => {
                //  console.error(res.data.paymentScheduleDetails[0])
                console.log("Loan details data: ", res);
                if (res["statusCode"] == 200) {
                    setdeferdata({
                        paymentScheduleDetails: res?.data?.data?.paymentScheduleDetails,
                        curentscheduleDate: res?.data?.data?.paymentScheduleDetails[0],
                        defer: res?.data?.data?.defer_payment
                    });
                }
                else {
                    console.log(res)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <>
            {/* <div >
                <h5>Payment Schedule</h5>
            </div> */}

            <div>
                <div>
                    <h5>Payment Details</h5>
                </div>
            </div>

            {
                userData?.paymentScheduleDetails?.length > 0 ?
                    <div>
                        <div className='frameshadow mb20px'>

                            <div className='employeblock'>

                                <div>     Financing Start Date </div>

                                <div> {userData.paymentScheduleDetails[0]?.createdat}</div>
                            </div>

                            <div className='employeblock'>

                                <div>     Financing Amount </div>

                                <div>{currency} {(userData.data?.loanamount)}</div>
                            </div>

                            <div className='employeblock'>

                                <div>     Payoff Amount </div>

                                <div>{currency} {(userData.paymentScheduleDetails[0]?.unpaidprincipal)}</div>
                            </div>

                            <div className='employeblock'>

                                <div>    Interest </div>

                                <div>{userData.data?.apr}%</div>
                            </div>

                            <div className='employeblock'>

                                <div>     Financing Term  </div>

                                <div>{userData.data?.loanterm} Months</div>
                            </div>

                            <div className='employeblock'>

                                <div>     Maturity Date  </div>

                                <div> {userData.paymentScheduleDetails[userData.paymentScheduleDetails.length - 1]?.scheduledate}</div>
                            </div>

                            <div className='employeblock'>

                                <div>     Next Payment Schedule Date  </div>

                                <div> {userData.paymentScheduleDetails[0]?.scheduledate}</div>
                            </div>




                        </div>

                        <div className='frameshadow mb20px'>
                            <div style={{ display: "flex", flexWrap: "nowrap", marginBottom: "10px" }}>
                                <div style={{ width: "50%" }}>
                                    <h5 style={{ paddingTop: "5px" }}>Repayment calendar Information</h5>
                                </div>
                           
                                <div style={{ width: "25%", textAlign: "right" }}>
                                    {userData?.stage === "fundingcontract" ?
                                        <CustomModal btnName={"Defer payment"} fun={getUserDetails} deferdata={deferdata} deferfunction={getUserDetails1} setdeferdata={setdeferdata}/>
                                        : ""}
                                </div>
                                {userData.paymentScheduleDetails.length!=1?<div style={{ width: "25%", textAlign: "right" }} >
                                    {userData?.stage === "fundingcontract" ?
                                        <CustomModal btnName={"Amend payment"} fun={getUserDetails} userdata={userData.paymentScheduleDetails}  />
                                        : ""}
                                </div>:<div style={{ width: "25%", textAlign: "right" }}><button disabled className='btn btn-primary'>Amend payment</button></div>}
                                <div style={{ width: "20%", textAlign: "right" }} >
                                    {userData?.stage === "fundingcontract" ?
                                        <CustomModal btnName={"Waive payment"} fun={getUserDetails} userdata={userData.paymentScheduleDetails}  />
                                        : ""}
                                </div>
                                <div style={{ width: "20%", textAlign: "right" }}>
                                    {userData?.stage === "fundingcontract" ?
                                        <CustomModal btnName={"Make Payment"} fun={getUserDetails} userdata={userData.paymentScheduleDetails} />
                                        : ""}
                                </div>
                            </div>
                            {userData?.paymentScheduleDetails?.length > 0 ?
                                <div>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='id'
                                        data={userData.paymentScheduleDetails}
                                        columns={columns} />
                                </div>
                                :
                                <div></div>
                            }
                        </div>
                    </div>
                    :
                    <div></div>
            }

            {
                userData?.transactionInformation?.length > 0 ?
                    <div>
                        <div className='frameshadow mb20px'>
                            <h5>Payment Ledger</h5>
                            <div>
                                <BootstrapTable
                                    bootstrap4
                                    keyField='id'
                                    data={userData.transactionInformation}
                                    columns={columnsTransaction} />
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
            }
        </>
    )
}

export default PaymentSchedule
