import React, { useEffect, useState } from "react";
import { AuthGet, Post } from "../../common_var/httpService";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const BorrowerLandingPage = () => {
  const { id, token, provider } = useParams();
  console.log("token", token);
  console.log("id", id);
  const navigate = useNavigate();

  const [isValidToken, setIsValidToken] = useState(false);

  let url = window.location.href;
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  let splittedURL = url.split("//");
  console.log("splittedURL::: ", splittedURL);

  // const borrowerToken = sessionStorage.getItem("borrower_token");

  useEffect(() => { 
    checkToken();
  }, []);

  const getBorrowerStatus = async () => {
    try {
      let res = await AuthGet(`auth/fetch-borrowerstatus/${id}`, "borrower");
      if (res.statusCode === 200) {
        console.log("BORROWER STATUS", res);

        if (res.data.status === "login") {
          navigate("/borrower/login");
        } else if (res.data.status === "onboarding") {
          sessionStorage.setItem("borrower_token", res.data?.borrowerToken);

          // Navigating to Onboarding Page.

          !isLocalhost
            ? window.open(
                `${splittedURL[0]}//app${res.data.key}-${
                  splittedURL[1].split(".")[0]
                }.${process.env.REACT_APP_ONBOARDING_DOMAIN}/onboarding/${
                  res.data.loan_id
                }/${res.data.borrowerToken}`,
                "_self"
              )
            : window.open(
                `${process.env.REACT_APP_ONBOARDING_URL}/onboarding/${res.data.loan_id}/${res.data.borrowerToken}`,
                "_self"
              );

          navigate("");
        } else if (res.data.status === "setpassword") {
          !provider
            ? navigate(`/setpassword/${token}/${id}`)
            : navigate(`/${provider}/setpassword/${token}/${id}`);
        } else if (res.data.status === "selectprovider") {
          sessionStorage.setItem("borrower_token", res.data?.borrowerToken);
          sessionStorage.setItem("userId", id);

          !provider
            ? navigate(`/selectprovider`)
            : navigate(`/${provider}/selectprovider`);
        } else if (res.data.status === "cosignor-onboarding") {
          const cosignorURL = !isLocalhost
            ? `${splittedURL[0]}//app${res.data.key}-${
                splittedURL[1].split(".")[0]
              }.${
                process.env.REACT_APP_ONBOARDING_DOMAIN
              }/cosignor/onboarding/${res.data.loan_id}/${
                res.data.borrowerToken
              }`
            : `${process.env.REACT_APP_ONBOARDING_URL}/cosignor/onboarding/${res.data.loan_id}/${res.data.borrowerToken}`;

          window.open(cosignorURL, "_self");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkToken = async () => {
    const bodyData = {
      token,
      id,
    };
    console.log("bodyData::: ", bodyData);
    try {
      const resp = await Post("auth/verify-token", bodyData, "borrower");
      if (resp.statusCode === 200) {
        console.warn("resp",resp)
      
        if(splittedURL[1].includes("verify-bank")){
          console.log("bank url set")
          sessionStorage.setItem("bank_url", id);
          sessionStorage.setItem('token', token);
          navigate("/borrower/login")
        }else{
          getBorrowerStatus();
        }
        // toast.success(resp.message[0]);
        // console.log(resp.message[0]);
      } else {
        toast.error("Invalid Token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return;
};

export default BorrowerLandingPage;
