const domain = window.location.href.split("/admin")[0];

export const updatePracticeUrl = (val) => {
  if (val?.length > 0) {
    val = val?.toLowerCase();
    let str = "";
    for (let el of val) {
      if (el === " ") str += "-";
      else str += el;
    }
    return `${domain}/${str}`;
  }
  return domain;
};

//allows only few char and restrict 0
export const contactNameValidation = (val) => {
  let response = val
    .replace(/[^a-zA-Z0-9-+_.@\s]/g, "") //allow only the char inside array
    .replace(/^[0\s]+/g, ""); //restrict 0 ans space at first index
  return response;
};

export const emailValidation = (val) => {
  let response = val
    .replace(/[^a-zA-Z0-9-+@._\s]/g, "") //allow only the char inside array
    .replace(/^[0-9]+/g, "") //restrict numbers at first index
    .replace(/\s/g, ""); //restrict space
  return response;
};

export const practiseValidation = (val) => {
  let response = val
    .replace(/[^a-zA-Z0-9-\s]/g, "") //allow only the char inside array
    .replace(/^[0\s]+/g, ""); //restrict 0 and space at first index
  return response;
};
export const providernameValidation = (val) => {
  let response = val
  .replace(/[^a-zA-Z0-9-+_.@\s]/g, "") //allow only the char inside array | Z0-9\ | <--- Number
    .replace(/^[0\s]+/g, ""); //restrict 0 and space at first index
  return response;
};

export const addressValidation = (val, length) => {
  val =val.replace(/^[0\s]+/g, "")
  if (val.length > length) {
    return val.substring(0, length);
  }
  return val;
};

export const phoneValidations = (val) => {
  val = val.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  return addressValidation(val, 14);
};

export const zipcodeValidations = (val) => {
  val = val.replace(/[^0-9]/g, "");
  return addressValidation(val, 5);
};

export const stateValidation = (val) => {
  let response = val
    .replace(/[^a-z\s.]/i, "") //allow only the char inside array
    .replace(/^[.\s]+/g, "") //restrict 0 and space at first index
    .replace(/[.]{2,}/g, ".");
  return response;
};
export const passwordValidation = (val) => {
  val = val.replace(/\s/g, "");
  return val;
};

export const nameValidation = (val) => {
  val = val
  .replace(/[^a-zA-Z ]/g, "")
  .replace(/^[0\s]+/g, "");
  return val
};
