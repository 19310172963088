import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import CustomModal from "../../Components/Modal/Modal";
import Toast from "../../Components/Toaster/Toast";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import deleteiconnew from "../../assets/images/deleteiconnew.svg";
import updateIcon from "../../assets/images/updateIcon.svg";

const CounterOffer = () => {
  // const [userData, setUserData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const queryParams = useLocation();
  const navigate = useNavigate();
  let params = queryParams.pathname.split("/");
  const loanId = params[params.length - 1];
  const loanStage = params[params.length - 2];
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [{ currency }, dispatch] = useStateValue();
  const [show, setShow] = useState(false);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    getlist();
  }, [queryParams]);

  const getlist = async () => {
    await AuthGet(`offers/fetch-offers/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          setOffersData(res.data);
          setSelectedOffers(res.data.filter((e) => e?.selected_flag == "Y"));
        }
      })
      // await AuthGet(`loanstage/${loanId}/${loanStage}`, "admin")
      //     .then((res) => {
      //         if (res["statusCode"] == 200) {
      //             setUserData({
      //                 data: res.data.from_details[0],
      //                 stage: res.data.stage[0].status_flag
      //             });
      //         }
      //     })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendOffers = async () => {
    await AuthGet(`offers/mail-counterOffer/${loanId}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          toast.success(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleOfferDelete = async (id) => {
    await AuthGet(`offers/drop-offer/${id}`, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          getlist();
          toast.success("Offer deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const columns = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        return slNo;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "financialamount",
      text: "Financial Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "interestrate",
      text: "Interest Rate",
      formatter: (cell) => {
        return cell + "%";
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "duration",
      text: "Term",
      formatter: (cell) => {
        return cell + " Months";
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "originationfee",
      text: "Origination Fee",
      formatter: (cell) => {
        if (cell === ".00") {
          return `${currency} 0.00`;
        }
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "monthlyamount",
      text: "Monthly Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "",
      text: "Update",
      formatter: (cell) => {
        return (
          <button className="btn deleteIcon">
            <img src={updateIcon} />
          </button>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setUpdateData({
            financialAmount: row.financialamount,
            originationFee: row.originationfee,
            interestRate: row.interestrate,
            duration: row.duration,
            id: row.id,
          });
          handleShow();
        },
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "",
      text: "Delete",
      formatter: (cell) => {
        return (
          <button className="btn deleteIcon ">
            <img src={deleteiconnew} />
          </button>
        );
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handleOfferDelete(row.id);
        },
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const selectedColumns = [
    {
      dataField: "",
      text: "S.NO",
      formatter: (e, column, columnIndex, row, rowIndex) => {
        let total = currentPage * 5;
        total = total - 5;
        let slNo = total + columnIndex + 1;
        return slNo;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "financialamount",
      text: "Financial Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "interestrate",
      text: "Interest Rate",
      formatter: (cell) => {
        return cell + "%";
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "duration",
      text: "Term",
      formatter: (cell) => {
        return cell + " Months";
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "originationfee",
      text: "Origination Fee",
      formatter: (cell) => {
        if (cell === ".00") {
          return `${currency} 0.00`;
        }
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
    {
      dataField: "monthlyamount",
      text: "Monthly Amount",
      formatter: (cell) => {
        return currency + cell;
      },
      style: {
        overflow: "hidden",
      },
    },
  ];

  const formikUpdateCounterOffer = useFormik({
    initialValues: {
      financialAmount: updateData?.financialAmount?.trim(),
      originationFee: updateData?.originationFee?.trim(),
      interestRate: updateData?.interestRate?.trim(),
      duration: updateData?.duration,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      financialAmount: Yup.string().required("Financial Amount is required"),
      originationFee: Yup.string().required("Origination Fee is required"),
      interestRate: Yup.string().required("Interest Rate is required"),
      duration: Yup.string().required("Duration is required"),
    }),
    onSubmit: async (values) => {
      values.loan_id = loanId;
      values.offer_id = updateData?.id;
      values.financialAmount = parseFloat(values.financialAmount);
      values.originationFee = parseFloat(values.originationFee);
      values.interestRate = parseFloat(values.interestRate);
      values.duration = parseFloat(values.duration);

      await AuthPost(`offers/change-counterOffer/`, values, "admin")
        .then((res) => {
          if (res.statusCode == 200) {
            handleClose();
            getlist();
            toast.success("Offer Updated");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
        });

      values.financialAmount = "";
      values.originationFee = "";
      values.interestRate = "";
      values.duration = "";
    },
  });

  return (
    <>
      <Toast />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formikUpdateCounterOffer.handleSubmit}>
            <div>
              <div>
                <label htmlFor="financialNumber">Financial Amount</label>
                <input
                  type="text"
                  id="financialNumber"
                  name="financialAmount"
                  class="form-control inputField mb10px"
                  onChange={formikUpdateCounterOffer.handleChange}
                  value={formikUpdateCounterOffer.values.financialAmount}
                  onBlur={formikUpdateCounterOffer.handleBlur}
                  placeholder="Enter Financial Amount"
                />
                {formikUpdateCounterOffer.touched.financialAmount &&
                formikUpdateCounterOffer.errors.financialAmount ? (
                  <p className="error_text">
                    {formikUpdateCounterOffer.errors.financialAmount}
                  </p>
                ) : null}
              </div>
              <div>
                <label htmlFor="interestRate">Interest Rate</label>
                <input
                  type="text"
                  id="interestRate"
                  name="interestRate"
                  class="form-control inputField mb10px"
                  value={formikUpdateCounterOffer.values.interestRate}
                  onChange={formikUpdateCounterOffer.handleChange}
                  onBlur={formikUpdateCounterOffer.handleBlur}
                  placeholder="Enter Interest Rate"
                />
                {formikUpdateCounterOffer.touched.interestRate &&
                formikUpdateCounterOffer.errors.interestRate ? (
                  <p className="error_text">
                    {formikUpdateCounterOffer.errors.interestRate}
                  </p>
                ) : null}
              </div>
              <div>
                <label htmlFor="duration">Term</label>
                <select
                  name="duration"
                  id="duration"
                  className="form-control inputField mb10px"
                  value={formikUpdateCounterOffer.values.duration}
                  onChange={formikUpdateCounterOffer.handleChange}
                  onBlur={formikUpdateCounterOffer.handleBlur}
                >
                  <option value="">Duration</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="18">18</option>
                  <option value="24">24</option>
                </select>
                {formikUpdateCounterOffer.touched.duration &&
                formikUpdateCounterOffer.errors.duration ? (
                  <p className="error_text">
                    {formikUpdateCounterOffer.errors.duration}
                  </p>
                ) : null}
              </div>
              <div>
                <label htmlFor="originationFee">Origination Fee</label>
                <input
                  type="text"
                  id="originationFee"
                  name="originationFee"
                  class="form-control inputField mb10px"
                  value={formikUpdateCounterOffer.values.originationFee}
                  onChange={formikUpdateCounterOffer.handleChange}
                  onBlur={formikUpdateCounterOffer.handleBlur}
                  placeholder="Enter Origination Fee"
                />
                {formikUpdateCounterOffer.touched.originationFee &&
                formikUpdateCounterOffer.errors.originationFee ? (
                  <p className="error_text">
                    {formikUpdateCounterOffer.errors.originationFee}
                  </p>
                ) : null}
              </div>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      {/* <div>
                <h6>Counter Offer</h6>
            </div> */}

      <div>
        {/* <div className="  frameshadow mb20px"> */}

        {/* <div className='container-fluid' >

                        <div className='row'>

                            <div className='col-md-4'>Total Procedure Cost - {userData?.data?.loanamount} </div>
                            <div className='col-md-4'>  Down Payment -  {userData?.data?.downpayment} </div>
                            <div className='col-md-4'>   Monthly Income - {userData?.data?.monthlyincome} </div>
                        </div>
                    </div> */}

        {/* </div> */}
        <div className="  frameshadow mb20px">
          <div className="mb20px">
            <CustomModal
              btnName={"Generate Deal"}
              data={loanId}
              fun={getlist}
            />{" "}
            &nbsp;
            {/* <button className="btn btn-primary" onClick={handleSendOffers}>Send Counter Offer</button> */}
          </div>

          {selectedOffers?.length ? (
            <div>
              <div className="font_Weight_bold">Selected Deal</div>
              <div>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={selectedOffers}
                  columns={selectedColumns}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="font_Weight_bold">Available Deals</div>
          <div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={offersData}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterOffer;
