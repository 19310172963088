import React, { useEffect, useState } from "react";
import { Get } from "../../common_var/httpService";
import bannermain from "../../assets/images/bannermain.png";
import bubble from "../../assets/images/bubble.svg";
import telephone from "../../assets/images/telephone.svg";
import quote from "../../assets/images/quote.svg";
import listicon from "../../assets/images/tick.svg";
import howitwork from "../../assets/images/howitwork.jpg";
import benifts from "../../assets/images/benfits.jpg";
import check from "../../assets/images/check_circle.svg";
// import footerlogo from "../../assets/images/footer-logo.svg";
import "./landing.css";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import StartPage from "./StartPage";
import { useStateValue } from "../../Context/StateProvider";
import Brandlogo from "../../assets/images/brand_logo.svg";

function Landing() {
  const queryParams = useLocation()
  const [{currency }, dispatch] = useStateValue();
  const [loanPopup, setLoanPopup] = useState(false);
  const navigate = useNavigate();
  const [mobilemenu, setMobilemenu] = useState(false);
  let providername = queryParams.pathname?.split("/")

  const handleClick = () => {
    // setLoanPopup(true);
    dispatch({
      type: "SET_LOANAMOUNT_POPUP",
      payload: true,
    });
    // navigate("/select-loan");
    if(providername[1]){
     window.open(`/select-loan/${providername[1]}`)
    }else{
    window.open("/select-loan", "_blank");
  }
};
  const handleMobileClick = () => {
    setMobilemenu((current) => !current)
  }


  const handleLogin = () => {
    navigate("/borrower/login");
  };

  return (
    <div className="landingPage">
      {loanPopup && <StartPage />}
      <nav class="navbar navbar-expand-lg navbar-light logoheader plr40">
        <div class="container-fluid">
          <a class="navbar-brand brand_logo" href="#">
            <img src={Brandlogo} className="whiteFilter" />
          </a>
          {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button> */}

          <button onClick={handleMobileClick} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class={mobilemenu ? "collapse navbar-collapse " : "show navbar-collapse"} id="navbarSupportedContent">
            <div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 pr40" >
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#howitwork">
                    How it works
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#benefit">
                    Benefits
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>


            <div className="d-flex">
              <div className="applybutton cursorpointer" onClick={handleClick}>
                Start Now
              </div>
              <div className="applybutton2 cursorpointer" onClick={handleLogin}>
                Login
              </div>
            </div>

            {/* <Button className="applybutton" type="button" value={"Apply Now"} handleClick={handleClick} />
          <Button className="applybutton" type="button" value={"Login"} handleClick={navigate('/borrower/login')} /> */}
            {/* </div> */}
          </div>


        </div>
      </nav>
      <div className="container-fluid plr40" id="home">
        <div className="row pt100">
          <div className="col-md-6 bannercontentsection pr100">
            <div className="bannertitlefont">
              <span className="teal_text">Enabling</span> Affordable & Instant Medical Loans
            </div>
            <div className="pb30 bannerpara">
              A smarter and cost effective loan to make your medical treatments
              independent of bargains. Our functionally scalable solutions
              achieves your financial needs effectively.
            </div>
            <Button
              className="btn_secondary"
              type="button"
              value={"Start Now"}
              handleClick={handleClick}
            />
          </div>
          <div className="col-md-6 bannerimg plr0">
            <img className="floating" src={bannermain} />

          </div>


        </div>
      </div>

      <section className="pt80 plr40" id="howitwork">
        <div className="container-fluid">
          <div className="row flexcol_rev_mob">
            <div className="col-md-6  sectionimg">
              <img src={howitwork} className="" />
              <img className="bubble floating" src={bubble} />
            </div>
            <div className="col-md-6 bannercontentsection pl150">
              <p className="sectionsubtitle pb10"> HOW IT WORKS?</p>

              <p className="sectiontitle pb30">
                We have simplified the process to empower you to avail medical
                loans quickly.
              </p>

              <div className="sectionlist">
                <ul>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Get yourself pre-qualified</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Apply with your financial information</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Underwriting of application</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>Funding of contract</div>
                    </div>
                  </li>
                  <li>
                    <div className="displayFlex alignItemcenter  ">
                      <div className="mr10">
                        <img src={listicon} />
                      </div>
                      <div>ACH or Check payments</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className=" plr40" id="benefit">
        <div className="container-fluid pt80">
          <div className="row ">
            <div className="col-md-6  ">
              <p className="sectionsubtitle   pb10 ">BENEFITS</p>
              <p className="sectiontitle pb30">
                We have simplified the process to empower you to avail medical
                loans quickly.
              </p>
            </div>
          </div>

          <div className="row">

            <div className="col-md-6 ">
              <div className="benefitBlock">
                <div className="displayFlex ">
                  <div className="pr15">
                    <img src={listicon} />
                  </div>
                  <div>
                    <div className="benefittitle"> Saves time and money</div>
                    <p className="benefitcontent">
                      Our latest technology and optimized processes have been
                      specifically designed to increase efficiency and reduce costs.
                    </p>
                  </div>

                </div>

              </div>

              <div className="benefitBlock">
                <div className="displayFlex ">
                  <div className="pr15">
                    <img src={listicon} />
                  </div>
                  <div>
                    <div className="benefittitle">
                      Flexible repayment timeline
                    </div>
                    <p className="benefitcontent">
                      We offer tailored and stress-free repayment plans which will
                      best suit your budget and needs.
                    </p>
                  </div>

                </div>

              </div>
              <div className="benefitBlock">
                <div className="displayFlex ">
                  <div className="pr15">
                    <img src={listicon} />
                  </div>
                  <div>
                    <div className="benefittitle"> Zero collateral required</div>
                    <p className="benefitcontent">
                      We avoid collaterals which acts as a barrier for you to meet
                      your medical needs. Credit worthiness is the factor we
                      consider for loans.
                    </p>
                  </div>

                </div>

              </div>

              <div className="benefitBlock mb0">
                <div className="displayFlex ">
                  <div className="pr15">
                    <img src={listicon} />
                  </div>
                  <div>
                    <div className="benefittitle"> Minimal paperwork</div>
                    <p className="benefitcontent">
                      Our online medical loan application process is designed to be
                      as simple and straight forward which minimizes the dependency
                      on paperwork.
                    </p>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-md-6 bannercontentsection  pl150">
              <img src={benifts} />
              <img className="bubble2 floating" src={bubble} />
            </div>
          </div>
        </div>
      </section>
      <section className="mt80 mb50 contactbg " id="contact">
        <div className="container ptb60">
          <div className="row ">
            <div className="col-md-12  p0_mob">
              <div className="pb15 sectionsubtitle  textaligncenter ">
                CONTACT US
              </div>
              <div className="contacttext">
                As we envision the future in light of the challenges and
                opportunities of the present, we see the need As we envision the
                future in light of the challenges and opportunities of the
                present, we see the need.
              </div>
            </div>
          </div>

          <div className="row flexcolumnmob ">
            <div className="col-md-6 w100mob ">
              <div className="whiteframe ">
                <div className="pr40">
                  <img src={telephone} />
                </div>
                <div>
                  <div className="contacttitle ">Call Us Now</div>
                  <div className="contactcontect">
                    Need help with your Login? No problem! <br /> Our team is here
                    to help you. <br /> 508.475.9013
                  </div>
                  <div className="divcenter">
                    <button className="outlineButton"> Call Now</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 w100mob">
              <div className="whiteframe bordernone">
                <div className="pr40">
                  <img src={quote} />
                </div>
                <div>

                  <div className="contacttitle">Request a Quote</div>
                  <div className="contactcontect">
                    If you prefer to communicate via email, please click below.

                    Please feel free to ask any questions may you have, we
                    would love to hear from you.
                  </div>
                  <div className="divcenter">
                    <button className="outlineButton outlineButton2"> Let's Talk</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt80 mb50">
        <div className="container">
          <div className="row ">
            <div className="col-md-12  ">
              <div className="brand_logo">
                <img src={Brandlogo} className="whiteFilter" />
              </div>
            </div>
            <div className="col-md-4">


              <div className="footerlist">
                <ul className="">
                  <li>
                    <a href="#"><span style={{ color: "#40BB9D" }}>Home</span></a>
                  </li>
                  <li>
                    <a href="#"><span style={{ color: "#40BB9D" }}>How it works?</span></a>
                  </li>
                  <li>
                    <a href="#"><span style={{ color: "#40BB9D" }}>Benefits</span></a>
                  </li>
                  <li>
                    <a href="#"><span style={{ color: "#40BB9D" }}>Contact us</span></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className=" footerinput ">
                <div>
                  <label for="exampleInputEmail1" className="fw600">
                    {" "}
                    Get the freshest news from us
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Your email address…"
                  ></input>
                </div>
                <div>
                  <button className="">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className="container">
        <div className="footerline "></div>
        <div className="row fs12">
          <div className="col-md-6 ">
            <div>
              Copyright © 2019-2022 Theecode Technologies Private Limited. All
              Rights Reserved.
            </div>
          </div>
          <div className="col-md-6 displayFlex justifyend">
            <div className="footerbottomlist">
              <ul className="displayFlex alignItemcenter">
                <li>
                  <a href="#"> <span style={{ color: "#5A7072" }}>Terms & Conditions</span></a>
                </li>
                <li>*</li>
                <li>
                  <a href="#"><span style={{ color: "#5A7072" }}>Privacy Policy</span></a>
                </li>
                <li>*</li>
                <li>
                  <a href="#"> <span style={{ color: "#5A7072" }}>Accessibility</span></a>
                </li>
                <li>*</li>
                <li>
                  <a href="#"><span style={{ color: "#5A7072" }}>Legal</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Landing;
