import React, { useEffect, useState } from "react";
import { Get, Post, AuthPost } from "../../common_var/httpService";
import "./LoanProductMapping.scss";
import { toast } from 'react-hot-toast'

const LoanProductMapping = () => {
  const [layoutActive, setLayoutActive] = useState(null);
  const [layoutActiveIndex, setLayoutActiveIndex] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [providers, setProviders] = useState([]);
  const [layoutDetails, setLayoutDetails] = useState([]);

  const getProviders = async () => {
    try {
      let resp = await Get("loan/fetch-providers", "admin");
      if (resp.statusCode == 200) {
        setProviders(resp?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLayouts = async () => {
    try {
      let resp = await Get("api/layouts", "admin");
      if (resp.statusCode == 200) {
        setLayouts(resp?.data?.layout);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLayoutProviders = async () => {
    try {
      let resp = await Get('api/providerLayout', 'admin')
      if(resp.statusCode == 200) {
        setLayoutDetails(resp.layout_details)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProviders();
    getLayouts();
    getLayoutProviders();
  }, []);

  const removeAlreadyExistPId = (arr, pid) => {
    let tempPIDsArr = [...arr] 
    let existPId = tempPIDsArr.indexOf(pid)
    tempPIDsArr.splice(existPId, 1);
    return tempPIDsArr;
  }

  const handleProvidersIDs = (e, pId, layActive, layActiveIndex) => {
    e.preventDefault();
    let a = layoutDetails.filter((lay) => lay.layout_id == layoutActive)
    let b = layoutDetails.filter((lay) => lay.layout_id != layoutActive)

    let checkAlreadyExistPId;
    let tempArr = [];

    let selectedlayoutProviders =  layoutDetails.filter(el => el.layout_id === layoutActive);
    layoutDetails.map((el) => tempArr.push(...el.provider_id))
    checkAlreadyExistPId = tempArr.includes(pId) && !selectedlayoutProviders[0].provider_id.includes(pId) ? false : true;

    a.length > 0 && checkAlreadyExistPId ?
    setLayoutDetails([...b, {...a[0], provider_id: [...a[0].provider_id].includes(pId) ? removeAlreadyExistPId([...a[0].provider_id], pId) : [...a[0].provider_id?.map((ele) => ele),pId]}])
    : checkAlreadyExistPId && setLayoutDetails([...layoutDetails, {provider_id: [pId], layout_id: layActive, lay_index: layActiveIndex}])
  };

  const handleLayoutSelect = (e, lId, i) => {
    e.preventDefault();
    setLayoutActive(lId);
    setLayoutActiveIndex(i);
  };

  const handleAssignToAll = () => {
    if(layoutActive) {
      let tempArr = [];
    for (let i = 0; i < providers.length; i++) {
      tempArr.push(providers[i].id);
    }
    let filteredActiveLayArr = layoutDetails.filter((ele) => ele.layout_id == layoutActive)
    filteredActiveLayArr.length > 0 ?
    filteredActiveLayArr[0]?.provider_id.push(...tempArr) :
    setLayoutDetails([])
    setLayoutDetails([{lay_index: layoutActiveIndex, layout_id: layoutActive, provider_id: [...tempArr]}])
    } else {
      toast.error("Kindly select a layout")
    }
  };

  const handleSave = async () => {
    try {
      let resp = await AuthPost('api/layoutdealermappling', {layout_details: layoutDetails}, 'admin')
      if(resp.statusCode === 200) {
        toast.success("Saved Successfully")
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  return (
    <>
      <div className="titleText">Loan Product Mapping</div>
      <div className="loanpromap-container">
        <div className="layout-container">
          {layouts?.map((lay, index) => {
            layoutDetails?.forEach((el) => el.layout_id == lay.layout_id ? el.lay_index = index + 1 : null)
            return (
              <div key={index}
                onClick={(e) =>
                  handleLayoutSelect(e, lay?.layout_id, index + 1)
                }
                className={
                  layoutActive == lay?.layout_id
                    ? "single-layout layoutActive"
                    : "single-layout"
                }
              >
                <span
                  style={
                    layoutActive == lay?.layout_id
                      ? { backgroundColor: "#fff", color: "#02160d" }
                      : { backgroundColor: "#02160d", color: "#fff" }
                  }
                  className="lay-badge"
                >
                  {index + 1}
                </span>{" "}
                {lay?.layout_name}
              </div>
            );
          })}
        </div>
        <div className="btns-container">
        <button
            onClick={(e) => handleAssignToAll(e)}
            className="btn btn-primary"
          >
            Assign to all
          </button>
        <button
            onClick={() => setLayoutDetails([])}
            className="btn btn-primary widthFull"
          >
            Reset
          </button>
          <button
            onClick={handleSave}
            className="btn btn-primary widthFull"
          >
            Save
          </button>
        </div>
        <div className="provider-container">
          {providers?.map((provider, index) => {
            return (
              <div key={index}
                className="single-provider"
                onClick={(e) => {
                  layoutActive && handleProvidersIDs(e, provider?.id, layoutActive, layoutActiveIndex);
                }}
              >
                <div>{provider?.practicename}</div>
                {layoutDetails?.map((lay, i) => {
                  return (
                    lay?.provider_id?.includes(provider?.id) && (
                      <div key={i} className="custom-badge">{lay?.lay_index}</div>
                  )
                  )
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LoanProductMapping;
