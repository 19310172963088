import React, { useEffect, useState } from "react";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useLocation, useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from "react-bootstrap/Modal";
import {EmailRegex, name} from '../../Components/Regex/Regex'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import Toast from "../../Components/Toaster/Toast";
import { emailValidation } from "../CommonFunctions/Validations";
import { Roles } from "../../data_store";
import closeIcon from '../../assets/images/close-icon.svg'

function Users() {
  const navigate = useNavigate();
  const [roleData, setRoleData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () =>{ 
  formik.resetForm()
  setShow(false);
  }
  const handleShow = () => setShow(true);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  });

  const user = JSON.parse(sessionStorage.getItem('resuser'))
  const tabs = JSON.parse(sessionStorage.getItem("tabs"));
  const queryParams = useLocation();
  const loanParams = queryParams.pathname.slice(7);

   
  const formik=useFormik({
    initialValues:{
    firstName:'',
    lastName:'',
    email:'',
    role:'',
  },enableReinitialize:true,
    validationSchema:Yup.object({
      firstName:Yup.string()
    .required('*First Name is Required')
    .min(1, 'First Name Must Be Between 1 and 50 Characters')
    .max(50, 'First Name Must Be Between 1 and 50 Characters'),
    
    lastName:Yup.string()
    .required('*Last Name is Required').min(1, 'Last Name Must Be Between 1 and 50 Characters')
                .max(50, 'Last Name Must Be Between 1 and 50 Characters'),

    email:Yup.string()
    .required('*Email is required')
    .email('*Enter a Valid Email')
    .matches(EmailRegex, '*Enter a Valid Email'),
    
    role:Yup.string()
   .required('*Role is Required'),
    }),
    onSubmit:async(values)=>{
    
    let sendData = {
      firstname: values.firstName.toString(),
      lastname: values.lastName.toString(),
      email: (values?.email.toString())?.toLowerCase(),
      role: parseInt(values?.role)
    }
    await AuthPost(`users/create-admin`, sendData, 'admin')
      .then((res) => {
        if (res?.statusCode === 200) {
          formik.resetForm()
          getUserList();
          getRoles();
          handleClose();
          toast.success("Portal User Is Added Successfully",  {duration: 4000})
        }else {
          toast.error(res.message,  {duration: 4000})
        }
      })
    }
})        




  useEffect(() => {
    getUserList();
    getRoles();
  }, []);


  const getUserList = async () => {
    await AuthGet(`users/fetch-all-admin`, 'admin')
      .then((res) => {
        console.log('res::: ', res);
        
        const enrichedLoanData = res?.data.map((item, index) => ({
          
          ...item,
          serialNumber: index + 1,
        }));
        // if(res?.statusCode === 200){
          setUserData(enrichedLoanData);
          setPage(Math.ceil(enrichedLoanData.length / 10));
          setCurrentPage(1);
        // }
      })
  }

  const getRoles = async () => {
    await AuthGet(`roles/fetch-admin`, 'admin').then((res) => {
      if (res?.statusCode === 200) {
        if (user.role === 1) {
          const rolesList = res.data
            .filter((role) => role.id !== user.role)
            .map((role) => {
              role.displayName = Roles[role.name];
              return role;
            });
          setRoleData(rolesList);
        } else {
          const rolesList = res.data
            .filter((role) => role.id > user.role)
            .map((role) => {
              role.displayName = Roles[role.name];
              return role;
            });
          setRoleData(rolesList);
        }
      }
    });
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const input_name = e.target.name;
    const input_value = e.target.value;
    setForm({ ...form, [input_name]: input_value });
  };


  // const submit = async (e) => {
  //   handleClose();
  //   e.preventDefault();
  //   let sendData = {
  //     firstname: form?.firstName.toString(),
  //     lastname: form?.lastName.toString(),
  //     email: form?.email.toString(),
  //     role: parseInt(form?.role)
  //   }

  //   await AuthPost(`users/create-admin`, sendData, 'admin')
  //     .then((res) => {
  //       if (res?.statusCode === 200) {
  //         setForm({
  //           firstName: '',
  //           lastName: '',
  //           email: '',
  //           role: '',
  //         })
  //         getUserList();
  //         getRoles();
  //       }
  //     })

  // }

  const columns = [
    {
      dataField: 'serialNumber', text: 'S.NO', sort: true, 
      // formatter: (e, column, columnIndex, row, rowIndex) => 
      // { let total = currentPage * 10;
      //    total = total - 10; 
      //    let slNo = total + columnIndex + 1; 
      //    return slNo; },
          headerStyle: { width: '50px', },
         style: {
          overflow: 'hidden',
          textAlign:'center'
        }
    },
      { 
        dataField: "email", text: 'Email', sort: true, 
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            navigate(`/admin/users/${row.id}`);
        },
      },
      style: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline',
        overflow: 'hidden'
      },
      headerStyle: { width: '150px', },
    
    },
       { dataField: "firstname", text: 'First Name', sort: true, headerStyle: { width: '150px', }, style: {
        overflow: 'hidden'
      } },
        { dataField: "lastname", text: 'Last Name', sort: true, headerStyle: { width: '100px', }, style: {
          overflow: 'hidden'
        } },
         { dataField: "role_name", text: 'Role', sort: true, headerStyle: { width: '150px', }, style: {
          overflow: 'hidden'
        } }];

  const defaultSorted = [
    // {
    //   dataField: 'email',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'firstname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'lastname',
    //   order: 'desc'
    // },
    // {
    //   dataField: 'role_name',
    //   order: 'desc'
    // }
  ];

  const pagination = paginationFactory({
    page: page,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      setCurrentPage(page);
    },
    onSizePerPageChange: function (page, sizePerPage) {
    }
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (<div>
     <Toast />

    <div className="breadcrumbsection">
      <div>
        <div className="titleText ">Portal Users</div>
      </div>
      {tabs[loanParams].find((tab)=> tab.name === 'add_portal_user') &&<button
        className="btn btn-primary"
        onClick={() => handleShow()}
      >
        Add Portal User
      </button>}
    </div>
    <div>
      <Modal show={show} className="addnewpopup">
        <Modal.Header>
          <div>
            <h4>Add Portal User</h4>
          </div>
          <img src={closeIcon} alt="close" onClick={handleClose}/>
        </Modal.Header>
        <form>
          <div className="modelScroll">
            <Modal.Body>
              <section
                id="createApplicationFromBackend"
                className="start_application"
              >

                <form
                  className="formclass signupForm"
                  style={{ width: '100%' }}
                >
                  <div className="row">
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                      <label htmlFor="firstName">First Name</label>
                        <input
                          placeholder="First Name"
                          id="firstName"
                          name="firstName"
                          className="adminInput form-application"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "firstName",
                              name(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                      </div>
                      {formik.touched.firstName && formik.errors.firstName ? (
                                    <p className="error_text text-danger">{formik.errors.firstName}</p>
                                ) : null}
                    </div>
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                      <label htmlFor="lastName">Last Name</label>
                        <input
                          placeholder="Last Name"
                          className="adminInput form-application"
                          id="lastName"
                          name="lastName"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "lastName",
                              name(e.target.value)
                            );
                          }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                        />
                      </div>
                      {formik.touched.lastName && formik.errors.lastName ? (
                                    <p className="error_text text-danger">{formik.errors.lastName}</p>
                                ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                      <label htmlFor="email">Email</label>
                        <input
                          placeholder="Email"
                          className="adminInput form-application"
                          id="email"
                          name="email"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "email",
                              emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                                    <p className="error_text text-danger">{formik.errors.email}</p>
                                ) : null}
                    </div>
                    <div className="mb_24 col-md-6">
                      <div className="float-label">
                      <label htmlFor="role">Roles</label>
                        <select
                          placeholder="Roles"
                          className="adminInput form-application"
                          id="role"
                          name="role"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.role}
                        >
                          <option defaultValue value=''>Pick Role</option>
                          {roleData.length > 0 && roleData?.map((data) => {
                            const { id, displayName } = data;
                            return (
                              <option value={id} key={id}>{displayName}</option>
                            )
                          })}

                        </select>
                      </div>
                      {formik.touched.role && formik.errors.role ? (
                                    <p className="error_text text-danger">{formik.errors.role}</p>
                                ) : null}
                    </div>
                  </div>
                </form>
              </section>
            </Modal.Body>
          </div>
        </form>
        <Modal.Footer>
          <div>
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>

    <div className="frameshadow">
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={userData}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <SearchBar {...props.searchProps} />
              <ClearSearchButton {...props.searchProps} />
              <hr />
              <BootstrapTable
                bootstrap4
                key='loan_ref'
                defaultSorted={defaultSorted}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  </div>);
}

export default Users;







